import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios";
import type { Staff } from "../types";
import { ChangePasswordInputs } from "../interfaces/ChangePassword.inferface";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchStaffs = async (queryParams: {
  page: number;
  status?: string;
}): Promise<Staff[]> => {
  let params = `page=${queryParams.page}`;
  if (queryParams.status && queryParams.status !== "ALL") {
    params = params + `&status=${queryParams.status}`;
  }

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/staffs?${params}`
  );
  const { staffs } = response.data;
  return staffs ?? [];
};

const createStaff = async (payload: Partial<Staff>) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/staffs`,
    payload
  );
  const { staff } = response.data;
  return staff ?? null;
};

const updateStaff = async (staffId: number, payload: Partial<Staff>) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/staffs/${staffId}`,
    payload
  );
  const { staff } = response.data;
  return staff ?? null;
};

const deleteStaff = async (staffId: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/staffs/${staffId}`
  );
  const { id, deleted } = response.data;

  return { id, deleted } ?? false;
};

const changePasswordStaff = async (
  staffId: number,
  payload: ChangePasswordInputs
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/staffs/${staffId}/changePassword`,
    payload
  );
  const { staff } = response.data;
  return staff ?? null;
};

const useStaffs = (queryParams: any) => {
  return useQuery({
    queryKey: ["staffs"],
    queryFn: () => fetchStaffs(queryParams),
    staleTime: STALE_TIME,
    enabled: false,
  });
};

const useCreateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Partial<Staff>) => createStaff(payload),
    onSuccess: (data: Staff) => {
      queryClient.setQueryData(
        ["staffs"],
        (currentData: Staff[] | undefined) => {
          if (currentData && currentData.length) {
            return [...currentData, data];
          }
          return [data];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useUpdateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Partial<Staff>) => updateStaff(payload.id!, payload),
    onSuccess: (data: Staff) => {
      queryClient.setQueryData(
        ["staffs"],
        (currentData: Staff[] | undefined) => {
          if (currentData && currentData.length) {
            const newStaffs = currentData.filter((item) => item.id !== data.id);
            return [data, ...newStaffs];
          }
          return currentData;
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useDeleteStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (staffId: number) => deleteStaff(staffId),
    onSuccess: (data: { id: number }) => {
      queryClient.setQueryData(
        ["staffs"],
        (currentData: Staff[] | undefined) => {
          if (currentData && currentData.length) {
            return currentData.filter((item) => item.id !== data.id);
          }
          return [];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useChangePasswordStaff = () => {
  return useMutation({
    mutationFn: (payload: ChangePasswordInputs) =>
      changePasswordStaff(payload.id!, payload),
  });
};

export {
  useStaffs,
  fetchStaffs,
  useCreateStaff,
  useDeleteStaff,
  useUpdateStaff,
  useChangePasswordStaff,
};
