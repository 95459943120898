import { useEffect, useState } from "react";
import {
  createUserSession,
  suspendAccount,
  unsuspendAccount,
  useHostingAccounts,
} from "../../hooks/cpanel.hook";
import {
  Alert,
  Button,
  Card,
  CustomFlowbiteTheme,
  Dropdown,
  Table,
} from "flowbite-react";
import {
  AiFillInfoCircle,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AlphaHostingAccount } from "../../types/alphaHostingAccount.type";
import HostingAccountCreateModal from "../HostingAccount/CreateModal.component";

import { BiTrashAlt } from "react-icons/bi";
import { FiToggleLeft } from "react-icons/fi";
import DeleteHostingAccountModal from "../HostingAccount/DeleteModal.component";
import { toast } from "react-toastify";
import AlphaAccountHostingCreateModal from "../AlphaHostingAccount/CreateModal.component";
import AlphaDeleteHostingAccountModal from "../AlphaHostingAccount/DeleteModal.component";
import {
  createAlphaUserSession,
  useAlphaHostingAccounts,
} from "../../hooks/alphaPanel.hook";
import AlphaAccountHostingUserServiceModal from "../AlphaHostingAccount/UserServiceModal.component";

const RowActions = (props: {
  data: AlphaHostingAccount;
  meta: {
    handleOpenRemoveModal: (data: AlphaHostingAccount) => void;
    handleSuspend: (data: AlphaHostingAccount) => void;
    handleUnsuspend: (data: AlphaHostingAccount) => void;
    handleCreateUserSession: (data: AlphaHostingAccount) => void;
  };
}) => {
  const { data, meta } = props;

  const onClickRemove = () => {
    meta.handleOpenRemoveModal(data);
  };

  const onClickSuspend = () => {
    meta.handleSuspend(data);
  };

  const onClickUnsuspend = () => {
    meta.handleUnsuspend(data);
  };

  const onClickCreateUserSession = () => {
    meta.handleCreateUserSession(data);
  };

  return (
    <div className="flex items-center gap-3">
      <BiTrashAlt className="cursor-pointer" onClick={onClickRemove} />
      <Dropdown
        label=""
        dismissOnClick={false}
        renderTrigger={() => <span className="cursor-pointer">...</span>}
      >
        {/* {data.suspended === 0 ? (
          <Dropdown.Item onClick={onClickSuspend} color="gray">
            <FiToggleLeft className="mr-2 h-5 w-5" />
            <p>Suspend</p>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={onClickUnsuspend} color="gray">
            <FiToggleLeft className="mr-2 h-5 w-5" />
            <p>Unsuspend</p>
          </Dropdown.Item>
        )} */}
        <Dropdown.Item onClick={onClickCreateUserSession}>
          <p>AlphaPanel</p>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

const customCardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center gap-4 py-3",
  },
};

const columnHelper = createColumnHelper<AlphaHostingAccount>();

const columns = [
  columnHelper.accessor("id", {
    header: () => <span>#UID</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("email", {
    header: () => <span>Email</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.display({
    id: "services_count",
    header: () => <span>Service count</span>,
    cell: (props: any) => {
      return (
        <Button
          onClick={() =>
            props.table.options.meta.handleOpenUserService(
              props.row.original
            )
          }
          color="blue"
          size="xs"
        >
          {props.row.original.services_count}
        </Button>
      );
    },
  }),
  // columnHelper.accessor("plan", {
  //   header: () => <span>Rate (package)</span>,
  //   cell: (info) => info.getValue(),
  // }),
  // columnHelper.accessor("suspended", {
  //   header: () => <span>Suspended</span>,
  //   cell: (info) => {
  //     if (info.getValue() === 1) {
  //       return (
  //         <Alert color="failure" icon={AiFillInfoCircle}>
  //           Suspended
  //         </Alert>
  //       );
  //     }
  //     return "";
  //   },
  // }),
  columnHelper.display({
    id: "actions",
    header: () => <span>Actions</span>,
    cell: (props: any) => (
      <RowActions meta={props.table.options.meta} data={props.row.original} />
    ),
  }),
];

export default function AlphaPanelHostingAccountsTab() {
  const [page, setPage] = useState<number>(1);
  const { data: hostingAccounts, refetch } = useAlphaHostingAccounts({ page });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [openUserServiceModal, setOpenUserServiceModal] =
    useState<boolean>(false);
  const [selectedDelete, setSelectedDelete] = useState<AlphaHostingAccount>();
  const [selectedAccountForUserSerivce, setSelectedAccountForUserSerivce] =
    useState<AlphaHostingAccount | null>(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChangePage = (page: number) => {
    setPage(page);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleOpenRemoveModal = (data: AlphaHostingAccount) => {
    setOpenRemoveModal(true);
    setSelectedDelete(data);
  };

  const handleCloseRemoveModal = () => {
    setOpenRemoveModal(false);
  };

  const handleCloseUserServiceModal = () => {
    setOpenUserServiceModal(false);
  }

  const handleSuspend = async (data: AlphaHostingAccount) => {
    // toast.info('Suspending account!');
    // await suspendAccount(data.user);
    // await refetch();
  };

  const handleUnsuspend = async (data: AlphaHostingAccount) => {
    // toast.info('Unsuspending account!');
    // await unsuspendAccount(data.user);
    // await refetch();
  };

  const handleCreateUserSession = async (data: AlphaHostingAccount) => {
    toast.info("Redirecting to Alpha panel!");
    const userSession = await createAlphaUserSession(data.id);
    if (userSession?.url) {
      const loginUrl = `${userSession.url}/sso-login?token=${userSession.token}`;
      window.open(loginUrl, "_blank");
    } else {
      toast.info("Something went wrong!");
    }
  };

  const handleOpenUserService = (account: AlphaHostingAccount) => {
    setSelectedAccountForUserSerivce(account);
    setOpenUserServiceModal(true);
  };

  const table = useReactTable({
    data: hostingAccounts ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      handleOpenRemoveModal,
      handleSuspend,
      handleUnsuspend,
      handleCreateUserSession,
      handleOpenUserService,
    },
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <div className="relative mt-1"></div>
        <div>
          <Button
            color="blue"
            size="sm"
            onClick={() => setOpenCreateModal(true)}
          >
            Add New
          </Button>
        </div>
      </div>
      <Card theme={customCardTheme}>
        <div className="flex px-5 items-center justify-between">
          <div className="flex items-center"></div>

          <Button.Group className="">
            <Button
              disabled={page === 1}
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page - 1)}
            >
              <AiOutlineLeft />
            </Button>
            <Button size="sm" className="border-none text-gray-500">
              {page}
            </Button>
            <Button
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page + 1)}
            >
              <AiOutlineRight />
            </Button>
          </Button.Group>
        </div>

        <Table>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.HeadCell className="bg-transparent" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ));
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <Table.Row
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>

      <AlphaAccountHostingCreateModal
        isOpen={openCreateModal}
        closeModal={handleCloseCreateModal}
        currentPage={page}
      />

      {selectedDelete && (
        <AlphaDeleteHostingAccountModal
          isOpen={openRemoveModal}
          closeModal={handleCloseRemoveModal}
          selectedAccount={selectedDelete}
          currentPage={page}
        />
      )}

      {selectedAccountForUserSerivce && (
        <AlphaAccountHostingUserServiceModal
          isOpen={openUserServiceModal}
          closeModal={handleCloseUserServiceModal}
          selectedAccount={selectedAccountForUserSerivce}
        />
      )}
    </div>
  );
}
