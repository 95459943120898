import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../axios";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchHostingAccounts = async (queryParams: { page: number }) => {
  const { page } = queryParams;
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts?page=${page}`
  );
  const { accounts } = response.data;
  return accounts ?? [];
};

const useHostingAccounts = ({ page = 1 }: { page: number }) => {
  return useQuery({
    queryKey: ["hostingAccounts", page],
    queryFn: () => fetchHostingAccounts({ page }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true,
  });
};

const createHostingAccount = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts`,
    payload
  );

  const { account, error } = response.data;

  if (error) {
    return { error };
  }

  return account ?? null;
};

const useCreateHostingAccount = () => {
  return useMutation({
    mutationFn: (payload: any) => createHostingAccount(payload),
    onSuccess: () => {},
  });
};

const deleteHostingAccount = async (username: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts?username=${username}`
  );

  return response ?? null;
};

const useDeleteHostingAccount = () => {
  return useMutation({
    mutationFn: (payload: any) => deleteHostingAccount(payload),
    onSuccess: () => {},
  });
};

const suspendAccount = async (username: string) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts/suspend`,
    {
      username,
    }
  );

  return response ?? null;
};

const unsuspendAccount = async (username: string) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts/unsuspend`,
    {
      username,
    }
  );

  return response ?? null;
};

const createUserSession = async (username: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts/user-session`,
    {
      username,
    }
  );

  const { userSession } = response.data;
  return userSession ?? null;
}

const assignToProject = async (projectId: number, cpanelUsername: string) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts/assign-to-project`,
    {
      projectId,
      cpanelUsername
    }
  );

  const { hostingRecord } = response.data;
  return hostingRecord ?? null;
}

const listAvailableHostingRecords = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/hosting/accounts/internal-hosting-records?skipAssigned=true`,
  );

  const { hostingRecords } = response.data;
  return hostingRecords ?? null;
}

export {
  useHostingAccounts,
  useCreateHostingAccount,
  useDeleteHostingAccount,
  suspendAccount,
  unsuspendAccount,
  createUserSession,
  assignToProject,
  listAvailableHostingRecords
};
