import { Button, Label, Modal, Select } from "flowbite-react";
import { Calendar } from "../../types";
import { useProjects } from "../../hooks/project.hook";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateCalendar } from "../../hooks/calendar.hook";

interface EditCalendarModalProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedCalendar: Calendar;
}

export default function EditCalendarModal(props: EditCalendarModalProps) {
  const { isOpen, closeModal, selectedCalendar } = props;
  const { data: projects, refetch: refectProject } = useProjects({ page: 1 });
  const { mutate: updateCalendar } = useUpdateCalendar();

  const initState = {
    projectId: selectedCalendar.project.id,
  };

  const [initialValues] = useState(initState);

  const validationSchema = Yup.object({
    projectId: Yup.number().required(),
  });

  const onSubmit = async (values: { projectId: number }) => {
    updateCalendar({ id: selectedCalendar.id, ...values });
    closeModal();
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const {
    register,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!projects?.length) {
      refectProject();
    }
  }, [projects, refectProject])

  return (
    <Modal show={isOpen} onClose={closeModal} size="2xl">
      <Modal.Header>Edit Calendar #{selectedCalendar.id}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex w-md flex-col gap-4 mb-5">
              <p className="font-bold">Project</p>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="projects" value="Project" />
                </div>
                <div className="col-span-2">
                  <Select id="projectId" sizing="sm" {...register("projectId")}>
                    {projects?.map((project) => (
                      <option value={project.id}>
                        #{project.id} - {project.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button pill color="blue" type="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
