import { useQuery } from "@tanstack/react-query";
import { Service } from "../types";
import axios from "../axios";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchServices = async (): Promise<Service[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/services`
  );
  const { services } = response.data;
  return services ?? [];
}

const fetchProjectServices = async (projectId: number): Promise<Service[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}/services`
  );
  const { services } = response.data;
  return services ?? [];
}

const useServices = () => {
  return useQuery({
    queryKey: ['services'],
    queryFn: () => fetchServices(),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true
  });
}

const useProjectServices = (projectId: number) => {
  return useQuery({
    queryKey: ['projectService', projectId],
    queryFn: () => fetchProjectServices(projectId),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true
  });
}

export { useServices, useProjectServices };
