import type { CustomFlowbiteTheme } from "flowbite-react";
import { Sidebar as FlowbiteSidebar, Flowbite } from "flowbite-react";
import { MAIN_TABS, TABS } from "../enums/shared.enum";
import { AiOutlineHome } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { LiaHandHoldingUsdSolid } from "react-icons/lia";
import { BsBox } from "react-icons/bs";
import { useServices } from "../hooks/service.hook";
import { useEffect } from "react";

const customSidebarTheme: CustomFlowbiteTheme = {
  sidebar: {
    root: {
      inner:
        "h-full overflow-y-auto overflow-x-hidden rounded bg-transparent py-4 px-3",
    },
    item: {
      active: "bg-[#4382C41A] text-[#4382C4]",
    },
  },
};

interface SidebarProps {
  handleOpenParentTab: (tab: MAIN_TABS) => void;
  handleOpenCurrentTab: (tab: string) => void;
  handleOpenServiceTab: (tab: string, serviceId: number) => void;
  currentParentTab: MAIN_TABS;
  currentTab: string;
}

export default function Sidebar(props: SidebarProps) {
  const { handleOpenParentTab, handleOpenCurrentTab, handleOpenServiceTab, currentParentTab, currentTab } = props;

  const { data: services, refetch } = useServices();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Flowbite theme={{ theme: customSidebarTheme }}>
      <FlowbiteSidebar
        className="w-full bg-transparent"
        aria-label="Sidebar with multi-level dropdown example"
      >
        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white mb-5">
          <p>{currentTab ? currentTab : currentParentTab}</p>
        </h5>

        <FlowbiteSidebar.Items className="bg-transparent">
          <FlowbiteSidebar.ItemGroup>
            <FlowbiteSidebar.Collapse
              onClick={() => handleOpenParentTab(MAIN_TABS.COMPANY)}
              open={currentParentTab === MAIN_TABS.COMPANY}
              label="Company"
              href="#"
              icon={AiOutlineHome}
            >
              <FlowbiteSidebar.Item
                active={currentTab === TABS.OVERVIEW}
                onClick={() => handleOpenCurrentTab(TABS.OVERVIEW)}
              >
                <p>Overview</p>
              </FlowbiteSidebar.Item>
              <FlowbiteSidebar.Item
                active={currentTab === TABS.STAFF}
                onClick={() => handleOpenCurrentTab(TABS.STAFF)}
              >
                <p>Staff</p>
              </FlowbiteSidebar.Item>
            </FlowbiteSidebar.Collapse>

            <FlowbiteSidebar.Collapse
              label="Clients"
              icon={FiUsers}
              onClick={() => handleOpenParentTab(MAIN_TABS.CLIENTS)}
              open={currentParentTab === MAIN_TABS.CLIENTS}
            >
              <FlowbiteSidebar.Item
                active={currentTab === TABS.USERS}
                href="#"
                onClick={() => handleOpenCurrentTab(TABS.USERS)}
              >
                Users
              </FlowbiteSidebar.Item>
              <FlowbiteSidebar.Item
                active={currentTab === TABS.PROJECTS}
                href="#"
                onClick={() => handleOpenCurrentTab(TABS.PROJECTS)}
              >
                Projects
              </FlowbiteSidebar.Item>
              <FlowbiteSidebar.Item
                active={currentTab === TABS.REQUEST_ACCESSES}
                href="#"
                onClick={() => handleOpenCurrentTab(TABS.REQUEST_ACCESSES)}
              >
                Request Accesses
              </FlowbiteSidebar.Item>
              <FlowbiteSidebar.Item
                active={currentTab === TABS.ACCESS_CODES}
                href="#"
                onClick={() => handleOpenCurrentTab(TABS.ACCESS_CODES)}
              >
                Promo Codes
              </FlowbiteSidebar.Item>
              <FlowbiteSidebar.Item
                active={currentTab === TABS.HOSTING_ACCOUNTS}
                href="#"
                onClick={() => handleOpenCurrentTab(TABS.HOSTING_ACCOUNTS)}
              >
                Hosting Accounts
              </FlowbiteSidebar.Item>

              <FlowbiteSidebar.Item
                active={currentTab === TABS.ALPHA_HOSTING_ACCOUNTS}
                href="#"
                onClick={() => handleOpenCurrentTab(TABS.ALPHA_HOSTING_ACCOUNTS)}
              >
                Alpha Panel
              </FlowbiteSidebar.Item>
            </FlowbiteSidebar.Collapse>

            <FlowbiteSidebar.Collapse
              label="Services"
              icon={LiaHandHoldingUsdSolid}
            >
              {services?.map((service) => (
                <FlowbiteSidebar.Item
                  active={currentTab === service.name}
                  href="#"
                  onClick={() => handleOpenServiceTab(service.name, service.id)}
                >
                  { service.name }
                </FlowbiteSidebar.Item>
              ))}
            </FlowbiteSidebar.Collapse>

            <FlowbiteSidebar.Collapse label="Products" icon={BsBox}></FlowbiteSidebar.Collapse>
          </FlowbiteSidebar.ItemGroup>
        </FlowbiteSidebar.Items>
      </FlowbiteSidebar>
    </Flowbite>
  );
}
