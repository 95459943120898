import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios";
import { AccessCode } from "../types/accessCode.type";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchAccessCodes = async (queryParams: {
  page: number;
}): Promise<AccessCode[]> => {
  const { page } = queryParams;
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/accessCodes?page=${page}`
  );
  const { accessCodes } = response.data;
  return accessCodes ?? [];
}

const useAccessCodes = ({ page = 1 }: { page: number }) => {
  return useQuery({
    queryKey: ['accessCodes', page],
    queryFn: () => fetchAccessCodes({ page }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true
  });
}

const createAccessCode = async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/accessCodes`
  );
  const { accessCode } = response.data;

  return accessCode ?? null;
}

const useCreateAccessCode = (queryParams: { page: number } = { page: 1}) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: () => createAccessCode(),
    onSuccess: (data: AccessCode) => {
      queryClient.setQueryData(
        ["accessCodes", page],
        (currentData: AccessCode[] | undefined) => {
          if (currentData && currentData.length) {
            const newRecords = currentData.filter((item) => item.id !== data.id);
            return [data, ...newRecords]
          }
          return currentData;
        },
        { updatedAt: Date.now() }
      )
    }
  })
}

export { useAccessCodes, useCreateAccessCode };
