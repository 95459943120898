import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Project } from "../../types";
import { useProjectServices, useServices } from "../../hooks/service.hook";
import { Service } from "../../types/service.type";
import { useUpdateProject } from "../../hooks/project.hook";

interface EditProjectModalProps {
  closeModal: () => void;
  isOpen: boolean;
  seletedProject: Project;
}

export default function EditProjectModal(props: EditProjectModalProps) {
  const { isOpen, closeModal, seletedProject } = props;
  const { mutate: updateProject } = useUpdateProject();
  const { data: projectServices, refetch: projectServiceRefetch } =
    useProjectServices(seletedProject.id);
  const { data: services, refetch } = useServices();
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);

  const initState = {
    name: seletedProject.name,
    balance: seletedProject.balance,
    geography: seletedProject.geography,
    status: seletedProject.status
  };

  const [initialValues] = useState(initState);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    balance: Yup.string().required(),
    geography: Yup.string().required(),
    status: Yup.string().required(),
  }).required();

  const onSubmit = async (_values: Partial<Project>) => {
    const serviceIds = selectedServices.map((service) => service.id);
    updateProject({ id: seletedProject.id, serviceIds });
    closeModal();
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const handleSelectService = (serviceId: number) => {
    const found = selectedServices.find((service) => service.id === serviceId);
    if (found) {
      setSelectedServices(
        selectedServices.filter((service) => service.id !== serviceId)
      );
    } else {
      const newService = services?.find((service) => service.id === serviceId);
      setSelectedServices([...selectedServices, newService!]);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!isOpen) {
      reset(initialValues);
    }
  }, [initialValues, isOpen, reset]);

  useEffect(() => {
    refetch();
    projectServiceRefetch();
  }, [refetch, projectServiceRefetch]);

  useEffect(() => {
    if (services?.length && projectServices?.length) {
      setSelectedServices(projectServices);
    }
  }, [services, projectServices]);

  return (
    <Modal show={isOpen} onClose={closeModal} size="2xl">
      <Modal.Header>Edit Project</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex w-md flex-col gap-4 mb-5">
              <p className="font-bold">Project General</p>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="balance" value="Balance" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="balance"
                    sizing="sm"
                    type="text"
                    disabled={true}
                    color={Boolean(errors.balance) ? "failure" : ""}
                    helperText={
                      Boolean(errors.balance) && errors.balance?.message
                    }
                    {...register("balance")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="name" value="Name" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="name"
                    sizing="sm"
                    type="text"
                    disabled={true}
                    color={Boolean(errors.name) ? "failure" : ""}
                    helperText={Boolean(errors.name) && errors.name?.message}
                    {...register("name")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="geography" value="Geography" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="geography"
                    sizing="sm"
                    type="text"
                    disabled={true}
                    color={Boolean(errors.geography) ? "failure" : ""}
                    helperText={
                      Boolean(errors.geography) && errors.geography?.message
                    }
                    {...register("geography")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="status" value="Status" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="status"
                    sizing="sm"
                    type="text"
                    disabled={true}
                    color={Boolean(errors.status) ? "failure" : ""}
                    helperText={
                      Boolean(errors.status) && errors.status?.message
                    }
                    {...register("status")}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="flex w-md flex-col gap-4 mt-5">
              <p className="font-bold">Services</p>
              <div className="grid grid-cols-1 items-center">
                {services?.map((service) => (
                  <div className="col-span-1">
                    <div className="flex items-center gap-2 mt-3">
                      <Checkbox
                        checked={selectedServices
                          .map((service) => service.id)
                          .includes(service.id)}
                        defaultChecked={false}
                        id="services"
                        onChange={() => handleSelectService(service.id)}
                      />
                      <Label className="flex" htmlFor="services">
                        <p>{service.name}</p>
                      </Label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button pill color="blue" type="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
