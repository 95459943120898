import { useEffect, useState } from "react";
import { Calendar } from "../../types";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  CustomFlowbiteTheme,
  Dropdown,
  Table,
  TextInput,
} from "flowbite-react";
import { FiEdit3, FiToggleLeft } from "react-icons/fi";
import { BiTrashAlt } from "react-icons/bi";
import { AiOutlineLeft, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import {
  useCalendars,
  useDeleteCalendar,
  useUpdateCalendar,
} from "../../hooks/calendar.hook";
import EditCalendarModal from "../Calendar/EditCalendarModal.component";
import DeleteCalendarModal from "../Calendar/DeleteCalendarModal.component";

enum STATUS_FILTER {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
}

const customCardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center gap-4 py-3",
  },
};

const columnHelper = createColumnHelper<Calendar>();

const columns = [
  columnHelper.display({
    id: "checkbox",
    header: () => <span></span>,
    cell: (props: any) => (
      <Checkbox
        onChange={(e) =>
          props.table.options.meta.handleSelectCalendar(e, props.row.original)
        }
      />
    ),
  }),
  columnHelper.accessor("id", {
    header: () => <span>#ID</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("project", {
    header: () => <span>Project ID</span>,
    cell: (info) => info.getValue().id,
  }),
  columnHelper.accessor("status", {
    header: () => <span>Status</span>,
    cell: (info) => {
      if (info.getValue() === "ACTIVE") {
        return (
          <Badge className="w-fit" color="success" size="sm">
            ACTIVE
          </Badge>
        );
      }
      if (info.getValue() === "SUSPENDED") {
        return (
          <Badge className="w-fit" color="gray" size="sm">
            SUSPENDED
          </Badge>
        );
      }
    },
  }),
  columnHelper.display({
    id: "actions",
    header: () => <span>Actions</span>,
    cell: (props: any) => (
      <RowActions meta={props.table.options.meta} data={props.row.original} />
    ),
  }),
];

const RowActions = (props: {
  data: Calendar;
  meta: {
    handleOpenRemoveModal: (data: Calendar) => void;
    handleOpenEditModal: (data: Calendar) => void;
    handleToggleStatus: (data: Calendar, status: string) => void;
  };
}) => {
  const { data, meta } = props;

  const onClickRemove = () => {
    meta.handleOpenRemoveModal(data);
  };

  const onClickEdit = () => {
    meta.handleOpenEditModal(data);
  };

  const onClickSuspend = () => {
    meta.handleToggleStatus(data, "SUSPENDED");
  };

  const onClickActivate = () => {
    meta.handleToggleStatus(data, "ACTIVE");
  };

  return (
    <div className="flex items-center gap-3">
      <FiEdit3 className="cursor-pointer" onClick={onClickEdit} />
      <BiTrashAlt className="cursor-pointer" onClick={onClickRemove} />
      <Dropdown
        label=""
        dismissOnClick={false}
        renderTrigger={() => <span>...</span>}
      >
        {data.status === "ACTIVE" ? (
          <Dropdown.Item onClick={onClickSuspend} color="gray">
            <FiToggleLeft className="mr-2 h-5 w-5" />
            <p>Suspend</p>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={onClickActivate} color="gray">
            <FiToggleLeft className="mr-2 h-5 w-5" />
            <p>Activate</p>
          </Dropdown.Item>
        )}
      </Dropdown>
    </div>
  );
};

export default function CalendarTab(props: { serviceId: number }) {
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedDeleteCalendar, setSelectedDeleteCalendar] =
    useState<Calendar>();
  const [selectedEditCalendar, setSelectedEdtiCalendar] = useState<Calendar>();
  const [selectedCalendars, setSelectedCalendars] = useState<Calendar[]>([]);
  const [statusFilter, setStatusFilter] = useState<STATUS_FILTER>(
    STATUS_FILTER.ALL
  );
  const { mutate: deleteCalendar } = useDeleteCalendar();
  const { mutate: updateCalendar } = useUpdateCalendar();

  const [page, setPage] = useState<number>(1);
  const { data: calendars, refetch } = useCalendars({
    serviceId: props.serviceId,
    page,
    status: statusFilter,
  });

  const handleOpenRemoveModal = (data: Calendar) => {
    setOpenRemoveModal(true);
    setSelectedDeleteCalendar(data);
  };

  const handleCloseRemoveModal = () => {
    setSelectedDeleteCalendar(undefined);
    setOpenRemoveModal(false);
  };

  const handleOpenEditModal = (data: Calendar) => {
    setOpenEditModal(true);
    setSelectedEdtiCalendar(data);
  };

  const handleCloseEditModal = () => {
    setSelectedEdtiCalendar(undefined);
    setOpenEditModal(false);
  };

  const handleSelectCalendar = (event: any, calendar: Calendar) => {
    let iCalendars = [];
    if (event.target.checked) {
      iCalendars = [...selectedCalendars, calendar];
    } else {
      iCalendars = selectedCalendars.filter((s) => s.id !== calendar.id);
    }
    setSelectedCalendars(iCalendars);
  };

  const handleDeleteMultiple = () => {
    for (const calendar of selectedCalendars) {
      deleteCalendar(calendar.id);
    }
    setSelectedCalendars([]);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handlechangeStatus = (status: STATUS_FILTER) => {
    setStatusFilter(status);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleToggleStatus = (calendar: Partial<Calendar>, status: string) => {
    updateCalendar({ id: calendar.id, status: status });
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const table = useReactTable({
    data: calendars ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      handleOpenRemoveModal,
      handleOpenEditModal,
      handleSelectCalendar,
      handleToggleStatus,
    },
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <div className="relative mt-1">
          <TextInput icon={AiOutlineSearch} id="search" placeholder="Search" />
        </div>
      </div>
      <Card theme={customCardTheme}>
        <div className="flex px-5 items-center justify-between">
          <div className="flex items-center">
            <Button.Group className="pe-5 border-e-2">
              <Button
                color={statusFilter === STATUS_FILTER.ALL ? "blue" : ""}
                size="sm"
                className={`border-none text-gray-500 ${
                  statusFilter === STATUS_FILTER.ALL ? "text-white" : ""
                }`}
                onClick={() => handlechangeStatus(STATUS_FILTER.ALL)}
              >
                All
              </Button>
              <Button
                color={statusFilter === STATUS_FILTER.ACTIVE ? "blue" : ""}
                size="sm"
                className={`border-none text-gray-500 ${
                  statusFilter === STATUS_FILTER.ACTIVE ? "text-white" : ""
                }`}
                onClick={() => handlechangeStatus(STATUS_FILTER.ACTIVE)}
              >
                Active
              </Button>
              <Button
                color={statusFilter === STATUS_FILTER.SUSPENDED ? "blue" : ""}
                size="sm"
                className={`border-none text-gray-500 ${
                  statusFilter === STATUS_FILTER.SUSPENDED ? "text-white" : ""
                }`}
                onClick={() => handlechangeStatus(STATUS_FILTER.SUSPENDED)}
              >
                Suspended
              </Button>
            </Button.Group>
            {selectedCalendars.length > 0 && (
              <div className="ps-8 flex items-center gap-5">
                <p>
                  <i>{selectedCalendars.length} selected</i>
                </p>
                <Button onClick={handleDeleteMultiple} size="sm" color="gray">
                  <BiTrashAlt className="mr-2 h-5 w-5" />
                  <p>Delete</p>
                </Button>
                <Button size="sm" color="gray">
                  <FiToggleLeft className="mr-2 h-5 w-5" />
                  <p>Turn active status off</p>
                </Button>
              </div>
            )}
          </div>

          <Button.Group className="">
            <Button
              disabled={page === 1}
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page - 1)}
            >
              <AiOutlineLeft />
            </Button>
            <Button size="sm" className="border-none text-gray-500">
              {page}
            </Button>
            <Button
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page + 1)}
            >
              <AiOutlineRight />
            </Button>
          </Button.Group>
        </div>
        <Table>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.HeadCell className="bg-transparent" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ));
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <Table.Row
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {selectedEditCalendar && (
          <EditCalendarModal
            isOpen={openEditModal}
            closeModal={handleCloseEditModal}
            selectedCalendar={selectedEditCalendar}
          />
        )}
        {selectedDeleteCalendar && (
          <DeleteCalendarModal
            isOpen={openRemoveModal}
            closeModal={handleCloseRemoveModal}
            seletedCalendar={selectedDeleteCalendar}
          />
        )}
      </Card>
    </div>
  );
}
