import { Button, Modal } from "flowbite-react";
import { Calendar } from "../../types";
import { useDeleteCalendar } from "../../hooks/calendar.hook";

interface DeleteCalendarModalProps {
  closeModal: () => void;
  isOpen: boolean;
  seletedCalendar: Calendar;
}

export default function DeleteCalendarModal(props: DeleteCalendarModalProps) {
  const { isOpen, closeModal, seletedCalendar } = props;
  const { mutate: deleteCalendar } = useDeleteCalendar();

  const onClickDelete = () => {
    deleteCalendar(seletedCalendar.id);
    closeModal();
  }

  return (
    <>
      <Modal show={isOpen} size="md" popup onClose={closeModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h1 className="text-lg font-bold mb-5">Delete Calendar #{seletedCalendar.id}</h1>
            <h3 className="mb-8 px-10 text-sm font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this calendar?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                outline
                color="failure"
                onClick={onClickDelete}
              >
                <p className="text-red-500">Delete</p>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
