import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Project, ProjectUser, Service } from "../types";
import axios from "../axios";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchProjects = async (queryParams: {
  page: number;
  status?: string;
}): Promise<Project[]> => {
  let params = `page=${queryParams.page}`;
  if (queryParams.status && queryParams.status !== "ALL") {
    params = params + `&status=${queryParams.status}`;
  }

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/projects?${params}`
  );
  const { projects } = response.data;
  return projects ?? [];
};

const createProject = async (payload: Partial<Project>) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/projects`,
    payload
  );
  const { project } = response.data;
  return project ?? null;
};

const updateProject = async (projectId: number, payload: Partial<Project>) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}`,
    payload
  );
  const { project } = response.data;
  return project ?? null;
};

const deleteProject = async (projectId: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}`
  );
  const { deleted } = response.data;

  return { id: projectId, deleted } ?? false;
};

const assignUserToProject = async (
  projectId: number,
  userId: number,
  serviceIds: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}/assignUser`,
    { userId, serviceIds }
  );
  const { success } = response.data;
  return { success } ?? false;
};

const fetchProjectUsers = async (projectId: number) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/projects/${projectId}/users`
  );
  const { projectUsers } = response.data;
  return projectUsers ?? [];
};

const updateProjectUser = async (
  projectUserId: number,
  payload: Partial<ProjectUser>
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/projectUsers/${projectUserId}`,
    payload
  );
  const { projectUser } = response.data;
  return projectUser ?? [];
};

const updateProjectService = async (
  projectServiceId: number,
  payload: Partial<Service>
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/services/units/${projectServiceId}`,
    payload
  );
  const { unit } = response.data;
  return unit ?? [];
};

const deleteProjectService = async (
  projectServiceId: number,
) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/services/units/${projectServiceId}`
  );
  const { unit } = response.data;
  return unit ?? [];
};

const deleteProjectUser = async (projectUserId: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/projectUsers/${projectUserId}`
  );
  const { success } = response.data;
  return { success } ?? [];
};

const useProjects = (queryParams: { page: number; status?: string }) => {
  const { page, status } = queryParams;

  return useQuery({
    queryKey: ["projects", page],
    queryFn: () => fetchProjects({ page, status }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true,
  });
};

const useCreateProject = (queryParams: { page: number } = { page: 1 }) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (payload: Partial<Project>) => createProject(payload),
    onSuccess: (data: Project) => {
      queryClient.setQueryData(
        ["projects", page],
        (currentData: Project[] | undefined) => {
          if (currentData && currentData.length) {
            return [...currentData, data];
          }
          return [data];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useUpdateProject = (queryParams: { page: number } = { page: 1 }) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (payload: Partial<Project>) =>
      updateProject(payload.id!, payload),
    onSuccess: (data: Project) => {
      queryClient.setQueryData(
        ["projects", page],
        (currentData: Project[] | undefined) => {
          if (currentData && currentData.length) {
            const newProjects = currentData.filter(
              (item) => item.id !== data.id
            );
            return [data, ...newProjects];
          }
          return currentData;
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useDeleteProject = (queryParams: { page: number } = { page: 1 }) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (projectId: number) => deleteProject(projectId),
    onSuccess: (data: { id: number }) => {
      queryClient.setQueryData(
        ["projects", page],
        (currentData: Project[] | undefined) => {
          if (currentData && currentData.length) {
            return currentData.filter((item) => item.id !== data.id);
          }
          return [];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useProjectUsers = (queryParams: { projectId: number }) => {
  const { projectId } = queryParams;

  return useQuery({
    queryKey: ["projectUsers", projectId],
    queryFn: () => fetchProjectUsers(projectId),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true,
  });
};

export {
  useProjects,
  useCreateProject,
  useDeleteProject,
  useUpdateProject,
  assignUserToProject,
  useProjectUsers,
  updateProjectUser,
  deleteProjectUser,
  updateProjectService,
  deleteProjectService
};
