import axios from "axios";

interface AuthProvider {
  isAuthenticated: boolean;
  email: null | string;
  password: null | string;
  login: null | string;
  signin(email: string, password: string): Promise<void>;
  signout(): Promise<void>;
}

export const authProvider: AuthProvider = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  email: localStorage.getItem('email') || null,
  login: localStorage.getItem('login') || null,
  password: null,
  async signin(email: string, password: string) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/auth/login`, {
        email: email,
        password: password
      })

      const { status, data } = response;
      if (status === 200) {
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('email', email);
        localStorage.setItem('login', data.login);
        authProvider.isAuthenticated = true;
        authProvider.email = email;
        authProvider.login = data.login;
        authProvider.password = password;

        window.location.href = '/dashboard';
      } else {
        authProvider.isAuthenticated = false;
        authProvider.email = '';
        authProvider.login = '';
        authProvider.password = '';
      }
    } catch (error) {
      // Handle error
    }
  },
  async signout() {
    await new Promise((r) => setTimeout(r, 500));
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('login');
    authProvider.isAuthenticated = false;
    authProvider.email = "";
    authProvider.login = '';
    authProvider.password = "";
  },
};
