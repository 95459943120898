import { Button, Modal } from "flowbite-react";
import { useCreateAccessCode } from "../../hooks/accessCode.hook";
import { useState } from "react";
import { BiCopy } from "react-icons/bi";

interface GenerateCodeModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export default function GenerateCodeModal(props: GenerateCodeModalProps) {
  const { isOpen, closeModal } = props;
  const { mutateAsync: createAccessCode } = useCreateAccessCode();
  const [accessCode, setAccessCode] = useState<string | null>();
  const [copied, setCopied] = useState<boolean>(false);

  const onCreate = async () => {
    try {
      const code = await createAccessCode();
      setAccessCode(code.code);
    } catch (error) {
      console.error(error);
    }
  };

  const onCloseModal = () => {
    setAccessCode(null);
    closeModal();
  }

  return (
    <Modal show={isOpen} onClose={onCloseModal} size="2xl">
      <Modal.Header>Generate Code</Modal.Header>
      <Modal.Body>
        {!accessCode && (
          <div className="space-y-6">
            <div>Do you want generate a new code</div>
            <hr />

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button pill color="blue" type="submit" onClick={onCreate}>
                Yes
              </Button>
            </div>
          </div>
        )}
        {accessCode && (
          <div className="space-y-6">
            <div className="flex items-center gap-2">
              <div>Your code: {accessCode}</div>
              <BiCopy
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(accessCode);
                  setCopied(true);
                }}
              />
              {copied && (
                <small>Copied!</small>
              )}

            </div>

            <hr />

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="blue" type="submit" onClick={onCloseModal}>
                OK
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
