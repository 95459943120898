import { Badge, Button, Label, Modal } from "flowbite-react";
import { AlphaHostingAccount } from "../../types/alphaHostingAccount.type";
import { useEffect } from "react";
import {
  suspendAlphaAccount,
  unsuspendAlphaAccount,
  useAlphaHostingUserServices,
} from "../../hooks/alphaPanel.hook";
import { toast } from "react-toastify";

interface AlphaAccountHostingUserServiceModalProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedAccount: AlphaHostingAccount;
}

export default function AlphaAccountHostingUserServiceModal(
  props: AlphaAccountHostingUserServiceModalProps
) {
  const { isOpen, closeModal, selectedAccount } = props;

  const { data: userServices, refetch } = useAlphaHostingUserServices(
    selectedAccount.id
  );

  useEffect(() => {
    if (selectedAccount && selectedAccount.id) {
      refetch();
    }
  }, [selectedAccount]);

  const handleSuspend = async () => {
    await suspendAlphaAccount(selectedAccount.id);
    refetch();
    toast.info("Suppended");
  };

  const handleUnsuspend = async () => {
    await unsuspendAlphaAccount(selectedAccount.id);
    refetch();
    toast.info("Unsuppended");
  };

  return (
    <Modal show={isOpen} onClose={closeModal} size="md">
      <Modal.Header>Services</Modal.Header>
      <Modal.Body>
        <div className="space-y-6 gap-2">
          <div className="flex w-md flex-col gap-4 mt-5">
            <div className="grid grid-cols-1 items-center">
              {userServices?.map((service: any) => (
                <div className="col-span-1">
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex gap-2">
                      <Badge
                        style={{
                          background: service.plan_color,
                          color: "white",
                        }}
                      >
                        {service.plan_name}
                      </Badge>

                      <Badge
                        className="w-fit"
                        color={
                          service.status === "active" ? "success" : "warning"
                        }
                        size="sm"
                      >
                        {service.status}
                      </Badge>
                    </div>

                    <div>
                      {service.status === "active" && (
                        <Button
                          className="flex-end"
                          size="xs"
                          color="gray"
                          onClick={handleSuspend}
                        >
                          Suppend
                        </Button>
                      )}
                      {service.status === "suspended" && (
                        <Button
                          size="xs"
                          color="gray"
                          onClick={handleUnsuspend}
                        >
                          Unsuppend
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
