import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios";
import { RequestAccess } from "../types/requestAccess.type";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchRequestAccesses = async (queryParams: {
  page: number;
}): Promise<RequestAccess[]> => {
  const { page } = queryParams;
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/requestAccesses?page=${page}`
  );
  const { requestAccesses } = response.data;
  return requestAccesses ?? [];
}

const useRequestAccesses = ({ page = 1 }: { page: number }) => {
  return useQuery({
    queryKey: ['requestAccesses', page],
    queryFn: () => fetchRequestAccesses({ page }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true
  });
}

const sendCodeToUser = async (id: number) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/requestAccesses/${id}/sendCode`
  );
  const { requestAccess } = response.data;

  return requestAccess ?? null;
}

const useSendCodeTouser = (queryParams: { page: number } = { page: 1}) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (id: number) => sendCodeToUser(id),
    onSuccess: (data: RequestAccess) => {
      queryClient.setQueryData(
        ["requestAccesses", page],
        (currentData: RequestAccess[] | undefined) => {
          if (currentData && currentData.length) {
            const newRecords = currentData.filter((item) => item.id !== data.id);
            return [data, ...newRecords]
          }
          return currentData;
        },
        { updatedAt: Date.now() }
      )
    }
  })
}

export { useRequestAccesses, useSendCodeTouser };
