import { createBrowserRouter, redirect } from "react-router-dom";
import Layout from "./layouts/main";

import AuthPage, { authLoader } from "./pages/Auth.page";
import DashboardPage, { protectedLoader } from "./pages/Dashboard.page";
import { authProvider } from "./hooks/auth.hook";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        path: "/",
        loader: authLoader,
        Component: AuthPage,
      },
      {
        path: "dashboard",
        loader: protectedLoader,
        Component: DashboardPage,
      },
    ],
  },
  {
    path: "/logout",
    async action() {
      await authProvider.signout();
      return redirect("/");
    },
  },
]);

export default router
