import { Button, Modal } from "flowbite-react";
import { Project } from "../../types";
import { useDeleteProject } from "../../hooks/project.hook";

interface DeleteProjectModalProps {
  closeModal: () => void;
  isOpen: boolean;
  seletedProject: Project;
}

export default function DeleteProjectModal(props: DeleteProjectModalProps) {
  const { isOpen, closeModal, seletedProject } = props;
  const { mutate: deleteProject } = useDeleteProject();

  const onClickDelete = () => {
    deleteProject(seletedProject.id);
    closeModal();
  }

  return (
    <>
      <Modal show={isOpen} size="md" popup onClose={closeModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h1 className="text-lg font-bold mb-5">Delete project?</h1>
            <h3 className="text-left mb-8 px-10 text-sm font-normal text-gray-500 dark:text-gray-400">
              All actions in the activity feed will be erased, and you can't reopen the project. There is no undo.
            </h3>
            <div className="flex justify-center gap-40">
              <Button
                className="w-full"
                outline
                color="failure"
                onClick={onClickDelete}
              >
                <p className="text-red-500">Delete</p>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
