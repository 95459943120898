import { Card } from "flowbite-react";
import SearchByImg from "../../assets/images/dashboard/search.svg";
import StatisticsImg from "../../assets/images/dashboard/statistics.svg";
import { useStatistics } from "../../hooks/statistic.hook";

export default function OverviewTab() {
  const { data: statistics, isFetched } = useStatistics();

  return (
    <div className="container mt-5">
      <div className="grid grid-cols-2 gap-5">
        <div>
          <Card className="bg-gradient-lighter">
            <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
              <div className="flex items-center gap-3">
                <img alt="search by" src={SearchByImg} />
                <span>Search By</span>
              </div>
            </h5>
            <div className="container p-5">
              <div className="grid grid-cols-1 gap-y-3">
                <div>
                  <label
                    htmlFor="user"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    User
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        className="w-full block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="project"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Project
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        className="w-full block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Date
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        className="w-full block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="bg-gradient-lighter">
            <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
              <div className="flex items-center gap-3">
                <img alt="statistics" src={StatisticsImg} />
                <span>Statistics</span>
              </div>
            </h5>

            <div className="container px-5 py-10">
              {isFetched && statistics && (
                <div className="grid grid-cols-2 gap-y-9">
                  <div>
                    <span className="text-2xl font-bold">
                      {statistics.usersCount}
                    </span>
                    <hr className="my-2 custom-hr" />
                    <span className="text-md">Total users</span>
                  </div>

                  <div>
                    <span className="text-2xl font-bold">
                      {statistics.projectsCount}
                    </span>
                    <hr className="my-2 custom-hr" />
                    <span className="text-md">Total projects</span>
                  </div>

                  <div>
                    <span className="text-2xl font-bold">
                      {statistics.newUsersCount}
                    </span>
                    <hr className="my-2 custom-hr" />
                    <span className="text-md">New users</span>
                  </div>

                  <div>
                    <span className="text-2xl font-bold">
                      {statistics.newProjectsCount}
                    </span>
                    <hr className="my-2 custom-hr" />
                    <span className="text-md">New projects</span>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
