import { useEffect, useState } from "react";
import { Project } from "../../types/project.type";
import {
  useDeleteProject,
  useProjects,
  useUpdateProject,
} from "../../hooks/project.hook";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  CustomFlowbiteTheme,
  Dropdown,
  Table,
  TextInput,
} from "flowbite-react";
import { AiOutlineLeft, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";
import { FiEdit3, FiToggleLeft } from "react-icons/fi";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import DeleteProjectModal from "../Project/DeleteProjectModal.component";
import AddProjectModal from "../Project/AddProjectModal.component";
import EditProjectModal from "../Project/EditProjectModal.component";
import ViewProjectModal from "../Project/ViewProjectModal.component";
import AssignUserModal from "../Project/AssignUserModal.component";

enum STATUS_FILTER {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
}

const customCardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center gap-4 py-3",
  },
};

const columnHelper = createColumnHelper<Project>();

const columns = [
  columnHelper.display({
    id: "checkbox",
    header: () => <span></span>,
    cell: (props: any) => (
      <Checkbox
        onChange={(e) =>
          props.table.options.meta.handleSelectProject(e, props.row.original)
        }
      />
    ),
  }),
  columnHelper.display({
    id: "indentify",
    header: () => <span>#ID</span>,
    cell: (props: any) => (
      <p
        className="cursor-pointer underline"
        onClick={() =>
          props.table.options.meta.handleOpenViewModal(props.row.original)
        }
      >
        {props.row.original.id}
      </p>
    ),
  }),
  columnHelper.accessor("name", {
    header: () => <span>Name</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("balance", {
    header: () => <span>Balance</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("geography", {
    header: () => <span>Geography</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: () => <span>Status</span>,
    cell: (info) => {
      if (info.getValue() === "ACTIVE") {
        return (
          <Badge className="w-fit" color="success" size="sm">
            ACTIVE
          </Badge>
        );
      }
      if (info.getValue() === "SUSPENDED") {
        return (
          <Badge className="w-fit" color="gray" size="sm">
            SUSPENDED
          </Badge>
        );
      }
    },
  }),
  columnHelper.accessor("users", {
    header: () => <span>Users</span>,
    cell: (info) => (
      <>
        {!info.getValue() ? <p>Empty</p> : info.getValue().map(user => <div>#{user.id} - {user.firstName} {user.lastName}</div>)}
      </>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: () => <span>Actions</span>,
    cell: (props: any) => (
      <RowActions meta={props.table.options.meta} data={props.row.original} />
    ),
  }),
];

const RowActions = (props: {
  data: Project;
  meta: {
    handleOpenRemoveModal: (data: Project) => void;
    handleOpenEditModal: (data: Project) => void;
    handleOpenAssignUserModal: (data: Project) => void;
    handleToggleStatus: (data: Project, status: string) => void;
  };
}) => {
  const { data, meta } = props;

  const onClickRemove = () => {
    meta.handleOpenRemoveModal(data);
  };

  const onClickEdit = () => {
    meta.handleOpenEditModal(data);
  };

  const onClickSuspend = () => {
    meta.handleToggleStatus(data, "SUSPENDED");
  };

  const onClickActivate = () => {
    meta.handleToggleStatus(data, "ACTIVE");
  };

  return (
    <div className="flex items-center gap-3">
      <FiEdit3 className="cursor-pointer" onClick={onClickEdit} />
      <BiTrashAlt className="cursor-pointer" onClick={onClickRemove} />
      <Dropdown
        label=""
        dismissOnClick={false}
        renderTrigger={() => <span className="cursor-pointer">...</span>}
      >
        {data.status === "ACTIVE" ? (
          <Dropdown.Item onClick={onClickSuspend} color="gray">
            <FiToggleLeft className="mr-2 h-5 w-5" />
            <p>Suspend</p>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={onClickActivate} color="gray">
            <FiToggleLeft className="mr-2 h-5 w-5" />
            <p>Activate</p>
          </Dropdown.Item>
        )}
      </Dropdown>
    </div>
  );
};

export default function ProjectTab() {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [openAssignUserModal, setOpenAssignUserModal] = useState<boolean>(false);
  const [seletedDeleteProject, setSeletedDeleteProject] = useState<Project>();
  const [selectedEditProject, setSelectedEdtiProject] = useState<Project>();
  const [selectedViewProject, setSelectedViewProject] = useState<Project>();
  const [selectedAssignUserProject, setSelectedAssignUserProject] = useState<Project>();
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [statusFilter, setStatusFilter] = useState<STATUS_FILTER>(
    STATUS_FILTER.ALL
  );

  const [page, setPage] = useState<number>(1);
  const { data: projects, refetch } = useProjects({
    page,
    status: statusFilter,
  });
  const { mutate: deleteProject } = useDeleteProject();
  const { mutate: updateProject } = useUpdateProject();

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenRemoveModal = (data: Project) => {
    setOpenRemoveModal(true);
    setSeletedDeleteProject(data);
  };

  const handleCloseRemoveModal = () => {
    setSeletedDeleteProject(undefined);
    setOpenRemoveModal(false);
  };

  const handleOpenEditModal = (data: Project) => {
    setOpenEditModal(true);
    setSelectedEdtiProject(data);
  };

  const handleCloseEditModal = () => {
    setSelectedEdtiProject(undefined);
    setOpenEditModal(false);
  };

  const handleOpenViewModal = (data: Project) => {
    setOpenViewModal(true);
    setSelectedViewProject(data);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedViewProject(undefined);
  };

  const handleOpenAssignUserModal = (data: Project) => {
    setOpenAssignUserModal(true);
    setSelectedAssignUserProject(data);
  }

  const handleCloseAssignUserModal = () => {
    setOpenAssignUserModal(false);
    setSelectedAssignUserProject(undefined);
  }

  const handleSelectProject = (event: any, project: Project) => {
    let iProjects = [];
    if (event.target.checked) {
      iProjects = [...selectedProjects, project];
    } else {
      iProjects = selectedProjects.filter((s) => s.id !== project.id);
    }
    setSelectedProjects(iProjects);
  };

  const handleDeleteMultiple = () => {
    for (const project of selectedProjects) {
      deleteProject(project.id);
    }
    setSelectedProjects([]);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handlechangeStatus = (status: STATUS_FILTER) => {
    setStatusFilter(status);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleToggleStatus = (project: Partial<Project>, status: string) => {
    updateProject({ id: project.id, status });
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const table = useReactTable({
    data: projects ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      handleOpenRemoveModal,
      handleOpenEditModal,
      handleOpenViewModal,
      handleSelectProject,
      handleToggleStatus,
      handleOpenAssignUserModal
    },
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <div className="relative mt-1">
          <TextInput icon={AiOutlineSearch} id="search" placeholder="Search" />
        </div>
        <div>
          <Button color="blue" size="sm" onClick={handleOpenAddModal}>
            Add New
          </Button>
        </div>
      </div>
      <Card theme={customCardTheme}>
        <div className="flex px-5 items-center justify-between">
          <div className="flex items-center">
            <Button.Group className="pe-5 border-e-2">
              <Button
                color={statusFilter === STATUS_FILTER.ALL ? "blue" : ""}
                size="sm"
                className={`border-none text-gray-500 ${
                  statusFilter === STATUS_FILTER.ALL ? "text-white" : ""
                }`}
                onClick={() => handlechangeStatus(STATUS_FILTER.ALL)}
              >
                All
              </Button>
              <Button
                color={statusFilter === STATUS_FILTER.ACTIVE ? "blue" : ""}
                size="sm"
                className={`border-none text-gray-500 ${
                  statusFilter === STATUS_FILTER.ACTIVE ? "text-white" : ""
                }`}
                onClick={() => handlechangeStatus(STATUS_FILTER.ACTIVE)}
              >
                Active
              </Button>
              <Button
                color={statusFilter === STATUS_FILTER.SUSPENDED ? "blue" : ""}
                size="sm"
                className={`border-none text-gray-500 ${
                  statusFilter === STATUS_FILTER.SUSPENDED ? "text-white" : ""
                }`}
                onClick={() => handlechangeStatus(STATUS_FILTER.SUSPENDED)}
              >
                Suspended
              </Button>
            </Button.Group>
            {selectedProjects.length > 0 && (
              <div className="ps-8 flex items-center gap-5">
                <p>
                  <i>{selectedProjects.length} selected</i>
                </p>
                <Button onClick={handleDeleteMultiple} size="sm" color="gray">
                  <BiTrashAlt className="mr-2 h-5 w-5" />
                  <p>Delete</p>
                </Button>
                <Button size="sm" color="gray">
                  <FiToggleLeft className="mr-2 h-5 w-5" />
                  <p>Turn active status off</p>
                </Button>
              </div>
            )}
          </div>

          <Button.Group className="">
            <Button
              disabled={page === 1}
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page - 1)}
            >
              <AiOutlineLeft />
            </Button>
            <Button size="sm" className="border-none text-gray-500">
              {page}
            </Button>
            <Button
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page + 1)}
            >
              <AiOutlineRight />
            </Button>
          </Button.Group>
        </div>
        <Table>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.HeadCell className="bg-transparent" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ));
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <Table.Row
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>

      <AddProjectModal isOpen={openAddModal} closeModal={handleCloseAddModal} />
      {seletedDeleteProject && (
        <DeleteProjectModal
          isOpen={openRemoveModal}
          closeModal={handleCloseRemoveModal}
          seletedProject={seletedDeleteProject}
        />
      )}
      {selectedEditProject && (
        <EditProjectModal
          isOpen={openEditModal}
          closeModal={handleCloseEditModal}
          seletedProject={selectedEditProject}
        />
      )}
      {selectedViewProject && (
        <ViewProjectModal
          seletedProject={selectedViewProject}
          isOpen={openViewModal}
          closeModal={handleCloseViewModal}
        />
      )}
      {selectedAssignUserProject && (
        <AssignUserModal
          selectedProject={selectedAssignUserProject}
          isOpen={openAssignUserModal}
          closeModal={handleCloseAssignUserModal}
        />
      )}
    </div>
  );
}
