import { redirect } from "react-router-dom";
import { authProvider } from "../hooks/auth.hook";
import LoginComponent from "../components/Login.component";

export async function authLoader() {
  if (authProvider.isAuthenticated) {
    return redirect("/dashboard");
  }
  return null;
}

export default function AuthPage() {
  return (
    <div className="container h-5/6 mx-auto flex justify-center items-center">
      <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 d-flex align-items-center">
        <LoginComponent />
      </div>
    </div>
  );
}
