export enum MAIN_TABS {
  COMPANY = "COMPANY",
  CLIENTS = "CLIENTS",
  SERVICES = "SERVICES",
  PRODUCTS = "PRODUCTS",
}

export enum TABS {
  OVERVIEW = "OVERVIEW",
  STAFF = "STAFF",
  USERS = "USERS",
  PROJECTS = "PROJECTS",
  REQUEST_ACCESSES = "REQUEST_ACCESSES",
  ACCESS_CODES = "ACCESS_CODES",
  HOSTING_ACCOUNTS = "HOSTING_ACCOUNTS",
  ALPHA_HOSTING_ACCOUNTS = "ALPHA_HOSTING_ACCOUNTS"
}
