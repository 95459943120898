import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../axios";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchHostingAccounts = async (queryParams: { page: number }) => {
  const { page } = queryParams;
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts?page=${page}`
  );
  const { accounts } = response.data;
  return accounts ?? [];
};

const useAlphaHostingAccounts = ({ page = 1 }: { page: number }) => {
  return useQuery({
    queryKey: ["hostingAccounts", page],
    queryFn: () => fetchHostingAccounts({ page }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true,
  });
};

const createAlphaHostingAccount = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts`,
    payload
  );

  const { account, error } = response.data;

  if (error) {
    return { error };
  }

  return account ?? null;
};

const useCreateAlphaHostingAccount = () => {
  return useMutation({
    mutationFn: (payload: any) => createAlphaHostingAccount(payload),
    onSuccess: () => {},
  });
};

const deleteHostingAccount = async (uid: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts?uid=${uid}`
  );

  return response ?? null;
};

const useDeleteAlphaHostingAccount = () => {
  return useMutation({
    mutationFn: (payload: any) => deleteHostingAccount(payload),
    onSuccess: () => {},
  });
};

const suspendAlphaAccount = async (uid: number) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts/suspend`,
    {
      uid,
    }
  );

  return response ?? null;
};

const unsuspendAlphaAccount = async (uid: number) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts/unsuspend`,
    {
      uid,
    }
  );

  return response ?? null;
};

const createAlphaUserSession = async (uid: number) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts/user-session`,
    {
      uid,
    }
  );

  const { userSession } = response.data;
  return userSession ?? null;
};

const assignToProjectAlpha = async (
  projectId: number,
  uid: number
) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts/assign-to-project`,
    {
      projectId,
      uid,
    }
  );

  const { hostingRecord } = response.data;
  return hostingRecord ?? null;
};

const listAvailableAlphaHostingRecords = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts/internal-hosting-records?skipAssigned=true`
  );

  const { hostingRecords } = response.data;
  return hostingRecords ?? null;
};

const fetchAlphaHostingPlans = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/plans`
  );
  const { plans } = response.data;
  return plans ?? null;
};

const useAlphaHostingPlans = () => {
  return useQuery({
    queryKey: ["alphaHostingPlans"],
    queryFn: () => fetchAlphaHostingPlans(),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true,
  });
};

const fetchUserServices = async (uid: number) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/alpha-hosting/accounts/user-services?uid=${uid}`
  );
  const { userSevices } = response.data;
  return userSevices ?? null;
};

const useAlphaHostingUserServices = (uid: number) => {
  return useQuery({
    queryKey: ["alphaHostingUserServices", uid],
    queryFn: () => fetchUserServices(uid),
    enabled: false,
    keepPreviousData: true,
  });
};

export {
  useAlphaHostingAccounts,
  useCreateAlphaHostingAccount,
  useDeleteAlphaHostingAccount,
  suspendAlphaAccount,
  unsuspendAlphaAccount,
  createAlphaUserSession,
  assignToProjectAlpha,
  listAvailableAlphaHostingRecords,
  useAlphaHostingPlans,
  useAlphaHostingUserServices
};
