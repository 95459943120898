import { Alert, Button, Label, Modal, Select, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import {
  useCreateHostingAccount,
  useHostingAccounts,
} from "../../hooks/cpanel.hook";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HostingAccount } from "../../types/hostingAccount.type";
import { useForm } from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  useAlphaHostingAccounts,
  useAlphaHostingPlans,
  useCreateAlphaHostingAccount,
} from "../../hooks/alphaPanel.hook";

interface AlphaAccountHostingCreateModalProps {
  closeModal: () => void;
  isOpen: boolean;
  currentPage: number;
}

export default function AlphaAccountHostingCreateModal(
  props: AlphaAccountHostingCreateModalProps
) {
  const { isOpen, closeModal, currentPage } = props;
  const { refetch } = useAlphaHostingAccounts({ page: currentPage });
  const { mutateAsync: createHostingAccount } = useCreateAlphaHostingAccount();
  const [serverError, setServerError] = useState();
  const { data: plans, refetch: refetchPlans } = useAlphaHostingPlans();

  const initState = {
    email: "",
    password: "",
    planId: 1,
  };

  const [initialValues] = useState(initState);

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    planId: Yup.number().required(),
  }).required();

  const onSubmit = async (values: Partial<HostingAccount>) => {
    try {
      const respnonse = await createHostingAccount(values);

      if (respnonse.error) {
        setServerError(respnonse.error);
        return;
      }

      await refetch();
      closeModal();
    } catch (error) {
      console.log("~~~~~~~ERRRR", error);
    }
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!isOpen) {
      reset(initialValues);
    }
  }, [initialValues, isOpen, reset]);

  useEffect(() => {
    refetchPlans();
  }, []);

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Modal show={isOpen} onClose={onCloseModal} size="2xl">
      <Modal.Header>Create Alpha Panel Account</Modal.Header>
      <Modal.Body>
        <div className="space-y-6 gap-2">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="grid grid-cols-3 items-center mb-3">
              <div className="col-span-1">
                <Label htmlFor="user" value="Email" />
              </div>
              <div className="col-span-2">
                <TextInput
                  id="user"
                  sizing="sm"
                  type="text"
                  color={Boolean(errors.email) ? "failure" : ""}
                  helperText={Boolean(errors.email) && errors.email?.message}
                  {...register("email")}
                />
              </div>
            </div>

            <div className="grid grid-cols-3 items-center mb-3">
              <div className="col-span-1">
                <Label htmlFor="password" value="Password" />
              </div>
              <div className="col-span-2">
                <TextInput
                  id="password"
                  sizing="sm"
                  type="text"
                  color={Boolean(errors.password) ? "failure" : ""}
                  helperText={
                    Boolean(errors.password) && errors.password?.message
                  }
                  {...register("password")}
                />
              </div>
            </div>

            <div className="grid grid-cols-3 items-center mb-3">
              <div className="col-span-1">
                <Label htmlFor="plan" value="Rate (Package)" />
              </div>
              <div className="col-span-2">
                <Select
                  id="plan"
                  sizing="sm"
                  color={Boolean(errors.planId) ? "failure" : ""}
                  helperText={Boolean(errors.planId) && errors.planId?.message}
                  {...register("planId")}
                >
                  {plans?.map((plan: any) => (
                    <option value={plan.id}>{plan.name}</option>
                  ))}
                </Select>
              </div>
            </div>

            {serverError && (
              <Alert color="failure" icon={AiFillInfoCircle}>
                {serverError}
              </Alert>
            )}

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button pill color="blue" type="submit">
                Create
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
