export type Statistics = {
  usersCount: number;
  newUsersCount: number;
  projectsCount: number;
  newProjectsCount: number;
}

export const defaultStatistics: Statistics = {
  usersCount: 0,
  newUsersCount: 0,
  projectsCount: 0,
  newProjectsCount: 0
}
