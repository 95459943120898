import * as countryPhoneData from "country-telephone-data";

export const countryPhoneDataOptions = countryPhoneData.allCountries.map(
  (item: { dialCode: string; iso2: string }) => {
    return {
      name: `${item.iso2.toUpperCase()} (+${item.dialCode})`,
      value: `+${item.dialCode}`,
    };
  }
);
