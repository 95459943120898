import { Button, Checkbox, Label, Modal, Select } from "flowbite-react";
import { Project } from "../../types";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUsers } from "../../hooks/user.hook";
import { assignUserToProject, useProjects } from "../../hooks/project.hook";
import { useProjectServices } from "../../hooks/service.hook";

interface AssignUserModalProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedProject: Project;
}

export default function AssignUserModal(props: AssignUserModalProps) {
  const { isOpen, closeModal, selectedProject } = props;
  // TODO: implement pagination for dropdown
  const { data: users, refetch } = useUsers({ page: 1 });
  const { refetch: refetchProject } = useProjects({ page: 1 });
  const { data: services, refetch: refetchServices } = useProjectServices(
    selectedProject.id
  );
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>([]);

  const initState = {
    userId: 0,
  };

  const [initialValues] = useState(initState);

  const validationSchema = Yup.object().shape({
    userId: Yup.number().required(),
  });

  const onSubmit = async (values: { userId: number }) => {
    const { userId } = values;
    const response = await assignUserToProject(
      selectedProject.id,
      userId,
      selectedServiceIds
    );
    if (response.success) {
      refetchProject();
      closeModal();
    }
    // TODO: Handle error
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const onSelectService = (serviceId: number) => {
    setSelectedServiceIds([...selectedServiceIds, serviceId]);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetchServices();
  }, [refetchServices]);

  useEffect(() => {
    if (!isOpen) {
      reset(initialValues);
    }
  }, [initialValues, isOpen, reset]);

  useEffect(() => {
    if (users?.length) {
      setValue("userId", users[0].id);
    }
  }, [users, users?.length, setValue]);

  return (
    <Modal show={isOpen} onClose={closeModal} size="2xl">
      <Modal.Header>Assign User to Project #{selectedProject.id}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex w-md flex-col gap-4 mb-5">
              <p className="font-bold">User</p>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="userId" value="Select User" />
                </div>
                <div className="col-span-2">
                  <Select
                    id="userId"
                    sizing="sm"
                    color={Boolean(errors.userId) ? "failure" : ""}
                    helperText={
                      Boolean(errors.userId) && errors.userId?.message
                    }
                    {...register("userId")}
                  >
                    {users?.map((user) => (
                      <option value={user.id}>
                        #{user.id} - {user.firstName} {user.lastName}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <hr className="my-5" />

              <p className="font-bold">Services</p>
              <div className="grid grid-cols-1 items-center">
                {services?.map((service) => (
                  <div className="col-span-1">
                    <div className="flex items-center gap-2 mt-3">
                      <Checkbox
                        defaultChecked={false}
                        id="services"
                        onChange={() => onSelectService(service.id)}
                      />
                      <Label className="flex" htmlFor="services">
                        <p>{service.name}</p>
                      </Label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button pill color="blue" type="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
