import { redirect } from "react-router-dom";
import { authProvider } from "../hooks/auth.hook";
import { useState } from "react";
import { Card } from "flowbite-react";
import Sidebar from "../components/Sidebar.component";
import OverviewTab from "../components/DashboardTabs/Overview.component";
import { MAIN_TABS, TABS } from "../enums/shared.enum";
import StaffTab from "../components/DashboardTabs/StaffTab.component";
import UserTab from "../components/DashboardTabs/UserTab.component";
import ProjectTab from "../components/DashboardTabs/ProjectTab.component";
import CalendarTab from "../components/DashboardTabs/Calendar.component";
import RequestAccessTab from "../components/DashboardTabs/RequestAccessTab.component";
import AccessCodeTab from "../components/DashboardTabs/AccessCodeTab.component";
import HostingAccountsTab from "../components/DashboardTabs/HostingAccountsTab.component";
import AlphaPanelHostingAccountsTab from "../components/DashboardTabs/PanelAlphaHostingAccountsTab.component";

export function protectedLoader() {
  if (!authProvider.isAuthenticated) {
    return redirect("/");
  }
  return null;
}

export default function DashboardPage() {
  const [currentParentTab, setCurrentParentTab] = useState<MAIN_TABS>(
    MAIN_TABS.COMPANY
  );
  const [currentTab, setCurrentTab] = useState<string>(TABS.OVERVIEW);
  const [selectedServiceId, setSelectedServiceId] = useState<number>(NaN);

  const handleOpenParentTab = (tab: MAIN_TABS) => {
    setCurrentParentTab(tab);
  };

  const handleOpenCurrentTab = (tab: string) => {
    setCurrentTab(tab);
  };

  const handleOpenServiceTab = (tab: string, serviceId: number) => {
    setCurrentTab(tab);
    setSelectedServiceId(serviceId);
  }

  return (
    <div className="container p-5">
      <Card className="bg-gradient-light">
        <div className="container">
          <div className="grid grid-cols-6">
            <div className="col-span-1">
              <Sidebar
                handleOpenParentTab={handleOpenParentTab}
                handleOpenCurrentTab={handleOpenCurrentTab}
                handleOpenServiceTab={handleOpenServiceTab}
                currentParentTab={currentParentTab}
                currentTab={currentTab}
              />
            </div>
            <div className="col-span-5 px-5">
              {currentTab === TABS.OVERVIEW && <OverviewTab />}
              {currentTab === TABS.STAFF && <StaffTab />}
              {currentTab === TABS.USERS && <UserTab />}
              {currentTab === TABS.PROJECTS && <ProjectTab />}
              {currentTab === TABS.REQUEST_ACCESSES && <RequestAccessTab />}
              {currentTab === TABS.ACCESS_CODES && <AccessCodeTab />}
              {currentTab === TABS.HOSTING_ACCOUNTS && <HostingAccountsTab />}
              {currentTab === 'Calendar' && <CalendarTab serviceId={selectedServiceId} />}
              {currentTab === TABS.ALPHA_HOSTING_ACCOUNTS && <AlphaPanelHostingAccountsTab />}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
