import { Button } from "flowbite-react";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { authProvider } from "../hooks/auth.hook";
import { useNavigate } from "react-router-dom";

export default function LoginComponent() {
  const navigate = useNavigate();

  const initState = {
    email: "",
    password: "",
  };

  const [initialValues] = useState(initState);

  const onSubmit = async (values: any) => {
    const { email, password } = values;
    await authProvider.signin(email, password);
    return navigate("dashboard");
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(">>", value, name, type);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="grid grid-cols-1 gap-y-5">
        <div className="sm:col-span-12">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="email"
                className="w-full block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Email"
                {...register("email", { required: "Email is required" })}
              />
            </div>
            {errors.email && (
              <div className="block text-sm font-medium leading-6 text-red-600 mt-2">
                {errors.email.message}
              </div>
            )}
          </div>
        </div>

        <div className="sm:col-span-12">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Password
          </label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <input
                type="password"
                className="w-full block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Password"
                {...register("password", {
                  required: "Password is required",
                })}
              />
            </div>
            {errors.password && (
              <div className="block text-sm font-medium leading-6 text-red-600 mt-2">
                {errors.password.message}
              </div>
            )}
          </div>
        </div>

        <div className="sm:col-span-12">
          <div className="w-full">
            <Button className="w-full" color="blue" type="submit">
              Login
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
