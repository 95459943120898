import { Button, Modal } from "flowbite-react";
import { useDeleteStaff } from "../../hooks/staff.hook";
import { Staff } from "../../types";

interface DeleteStaffModalProps {
  closeModal: () => void;
  isOpen: boolean;
  seletedStaff: Staff;
}

export default function DeleteStaffModal(props: DeleteStaffModalProps) {
  const { isOpen, closeModal, seletedStaff } = props;

  const { mutate: deleteStaff } = useDeleteStaff();

  const onClickDelete = () => {
    deleteStaff(seletedStaff.id);
    closeModal();
  }

  return (
    <>
      <Modal show={isOpen} size="md" popup onClose={closeModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h1 className="text-lg font-bold mb-5">Delete staff member</h1>
            <h3 className="mb-8 px-10 text-sm font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this staff member?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                outline
                color="failure"
                onClick={onClickDelete}
              >
                <p className="text-red-500">Delete</p>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
