import { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  CustomFlowbiteTheme,
  Dropdown,
  Table,
  TextInput,
} from "flowbite-react";
import { FiSend } from "react-icons/fi";
import { AiOutlineLeft, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import {
  useRequestAccesses,
  useSendCodeTouser,
} from "../../hooks/requestAccess.hook";
import { RequestAccess } from "../../types/requestAccess.type";
import dayjs from "dayjs";

const customCardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center gap-4 py-3",
  },
};

const columnHelper = createColumnHelper<RequestAccess>();

const columns = [
  columnHelper.display({
    id: "checkbox",
    header: () => <span></span>,
    cell: (props: any) => (
      <Checkbox
        onChange={(e) =>
          props.table.options.meta.handleSelectUser(e, props.row.original)
        }
      />
    ),
  }),
  columnHelper.accessor("id", {
    header: () => <span>#ID</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("email", {
    header: () => <span>Email</span>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("createdAt", {
    header: () => <span>Requested At</span>,
    cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY HH:mm"),
  }),
  columnHelper.accessor("sent", {
    header: () => <span>Sent</span>,
    cell: (info) => {
      if (info.getValue()) {
        return (
          <Badge className="w-fit" color="success" size="sm">
            Sent
          </Badge>
        )
      }

      return (
        <Badge className="w-fit" color="gray" size="sm">
          Not yet
        </Badge>
      )
    },
  }),
  columnHelper.accessor("updatedAt", {
    header: () => <span>Sent At</span>,
    cell: (info) => {
      if (info.row.original.sent) {
        return dayjs(info.getValue()).format("MM/DD/YYYY HH:mm")
      }
      return <></>
    },
  }),
  columnHelper.display({
    id: "actions",
    header: () => <span>Actions</span>,
    cell: (props: any) => (
      <RowActions meta={props.table.options.meta} data={props.row.original} />
    ),
  }),
];

const RowActions = (props: {
  data: RequestAccess;
  meta: {
    handleSendCode: (data: RequestAccess) => void;
  };
}) => {
  const { data, meta } = props;

  const onClickSendCode = () => {
    meta.handleSendCode(data);
  };

  return (
    <div className="flex items-center gap-3">
      {!data.sent && (
        <Dropdown
          label=""
          dismissOnClick={true}
          renderTrigger={() => <span className="cursor-pointer">...</span>}
        >
          <Dropdown.Item onClick={onClickSendCode} color="gray">
            <FiSend className="mr-2 h-5 w-5" />
            <p>Send Code</p>
          </Dropdown.Item>
        </Dropdown>
      )}
    </div>
  );
};

export default function RequestAccessTab() {
  const [page, setPage] = useState<number>(1);
  const { data: requestAccesses, refetch } = useRequestAccesses({ page });
  const { mutate: sendCode } = useSendCodeTouser({ page });

  const handleChangePage = (page: number) => {
    setPage(page);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleSendCode = (requestAccess: Partial<RequestAccess>) => {
    sendCode(requestAccess.id!);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const table = useReactTable({
    data: requestAccesses ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      handleSendCode,
    },
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <div className="relative mt-1">
          <TextInput icon={AiOutlineSearch} id="search" placeholder="Search" />
        </div>
      </div>
      <Card theme={customCardTheme}>
        <div className="flex px-5 items-center justify-between">
          <div className="flex items-center">
          </div>

          <Button.Group className="">
            <Button
              disabled={page === 1}
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page - 1)}
            >
              <AiOutlineLeft />
            </Button>
            <Button size="sm" className="border-none text-gray-500">
              {page}
            </Button>
            <Button
              size="sm"
              className="border-none text-gray-500"
              onClick={() => handleChangePage(page + 1)}
            >
              <AiOutlineRight />
            </Button>
          </Button.Group>
        </div>
        <Table>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => (
                <Table.HeadCell className="bg-transparent" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ));
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <Table.Row
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
}
