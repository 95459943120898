import { Outlet } from "react-router-dom";
import Header from "../components/Header.component";
import {} from "flowbite-react";

export default function Layout() {
  return (
    <div className="App-wrapper h-screen">
      <Header />
      <Outlet />
    </div>
  );
}
