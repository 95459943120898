import { useQuery } from '@tanstack/react-query'
import axios from '../axios';
import type { Statistics } from '../types';
import { defaultStatistics } from '../types';

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchStatistics = async (): Promise<Statistics> => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/statistics`)

  return response.data ?? defaultStatistics;
}

const useStatistics = () => {
  return useQuery({
    queryKey: ['statistics'],
    queryFn: () => fetchStatistics(),
    staleTime: STALE_TIME
  })
}

export { useStatistics, fetchStatistics }
