import { Button, Modal } from "flowbite-react";
import { useDeleteUser } from "../../hooks/user.hook";
import { User } from "../../types";

interface DeleteUserModalProps {
  closeModal: () => void;
  isOpen: boolean;
  seletedUser: User;
}

export default function DeleteUserModal(props: DeleteUserModalProps) {
  const { isOpen, closeModal, seletedUser } = props;

  const { mutate: deleteUser } = useDeleteUser();

  const onClickDelete = () => {
    deleteUser(seletedUser.id);
    closeModal();
  }

  return (
    <>
      <Modal show={isOpen} size="md" popup onClose={closeModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h1 className="text-lg font-bold mb-5">Delete user</h1>
            <h3 className="mb-8 px-10 text-sm font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this user?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                outline
                color="failure"
                onClick={onClickDelete}
              >
                <p className="text-red-500">Delete</p>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
