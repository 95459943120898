import {
  Badge,
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  Table,
  ToggleSwitch,
} from "flowbite-react";
import { OptionType, Project, ProjectUser, Service, User } from "../../types";
import { useProjectServices } from "../../hooks/service.hook";
import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { BiTrashAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import {
  assignUserToProject,
  deleteProjectService,
  deleteProjectUser,
  updateProjectService,
  updateProjectUser,
  useProjectUsers,
  useProjects,
  useUpdateProject,
} from "../../hooks/project.hook";
import DeleteProjectModal from "./DeleteProjectModal.component";
import { AsyncPaginate } from "react-select-async-paginate";
import { searchUsers } from "../../hooks/user.hook";
import { SingleValue } from "react-select";
import { assignToProject, listAvailableHostingRecords } from "../../hooks/cpanel.hook";
import { toast } from "react-toastify";
import { assignToProjectAlpha, listAvailableAlphaHostingRecords } from "../../hooks/alphaPanel.hook";

interface ViewProjectModalProps {
  closeModal: () => void;
  isOpen: boolean;
  seletedProject: Project;
}

export default function ViewProjectModal(props: ViewProjectModalProps) {
  const { isOpen, closeModal, seletedProject } = props;
  const { data: projectServices, refetch } = useProjectServices(
    seletedProject.id
  );
  const [projectUsers, setProjectUsers] = useState<ProjectUser[]>([]);
  const [project, setProject] = useState<Project>(seletedProject);
  const { mutate: updateProject } = useUpdateProject();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);
  const { data: xprojectUsers, refetch: refetchProjectUsers } = useProjectUsers(
    { projectId: seletedProject.id }
  );
  const { refetch: refetchProjects } = useProjects({ page: 1 });
  const [hostingRecords, setHostingRecords] = useState([]);
  const [alphaHostingRecords, setAlphaHostingRecords] = useState([]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetchProjectUsers();
  }, [refetchProjectUsers]);

  useEffect(() => {
    if (xprojectUsers?.length) {
      setProjectUsers(xprojectUsers);
    }
  }, [xprojectUsers]);

  const enabledHostingService = useMemo(() => {
    if (projectServices?.length && projectServices.length > 0) {
      const hostingService = projectServices?.find(
        (ps) => ps.name === "Hosting" && ps.status === "ACTIVE"
      );

      if (hostingService) {
        return true;
      }
    }
    return false;
  }, [projectServices]);

  const enabledAlphaHostingService = useMemo(() => {
    if (projectServices?.length && projectServices.length > 0) {
      const alphaHostingService = projectServices?.find(
        (ps) => ps.name === "AlphaHosting" && ps.status === "ACTIVE"
      );

      if (alphaHostingService) {
        return true;
      }
    }
    return false;
  }, [projectServices])

  const listHostingRecords = async () => {
    const data = await listAvailableHostingRecords();
    setHostingRecords(data);
  };

  const listAlphaHostingRecords = async () => {
    const data = await listAvailableAlphaHostingRecords();
    setAlphaHostingRecords(data);
  }

  useEffect(() => {
    if (enabledHostingService) {
      listHostingRecords();
    }
  }, [enabledHostingService]);

  useEffect(() => {
    if (enabledAlphaHostingService) {
      listAlphaHostingRecords();
    }
  }, [enabledAlphaHostingService])

  const handleToggleProjectStatus = async () => {
    const newStatus = project.status === "ACTIVE" ? "SUSPENDED" : "ACTIVE";
    updateProject({ id: seletedProject.id, status: newStatus });
    setProject({ ...project, status: newStatus });
  };

  const handleCloseRemoveModal = () => {
    setOpenDeleteModal(false);
    closeModal();
  };

  const onAssignUser = async (selectedOption: SingleValue<OptionType>) => {
    const { success } = await assignUserToProject(
      seletedProject.id,
      selectedOption!.value,
      []
    );
    if (success) {
      refetchProjectUsers();
      refetchProjects();
      setOpenAddUser(false);
    }
  };

  const promiseUserOptions = async (inputValue: string) => {
    const users = await searchUsers(inputValue);
    const existUserIds = projectUsers.map((pu) => pu.users.id);

    const filtered = users?.filter(
      (user: User) => !existUserIds.includes(user.id)
    );
    const options = filtered?.map((user: User) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName} #${user.id}`,
    }));

    return {
      options,
    };
  };

  const handleToggleUserStatus = async (projectUser: ProjectUser) => {
    const newStatus = projectUser.status === "ACTIVE" ? "SUSPENDED" : "ACTIVE";

    const updated = await updateProjectUser(projectUser.id, {
      status: newStatus,
    });
    if (updated) {
      refetchProjectUsers();
    }
  };

  const handleDeleteUser = async (projectUser: ProjectUser) => {
    const updated = await deleteProjectUser(projectUser.id);
    if (updated) {
      refetchProjectUsers();
      refetchProjects();
    }
  };

  const handleToggleServiceStatus = async (unit: Service) => {
    const newStatus = unit.status === "ACTIVE" ? "SUSPENDED" : "ACTIVE";
    const updated = await updateProjectService(unit.id, { status: newStatus });
    if (updated) {
      refetch();
    }
  };

  const handleDeleteService = async (unit: Service) => {
    const updated = await deleteProjectService(unit.id);
    if (updated) {
      refetch();
    }
  };

  const userColumnHelper = createColumnHelper<ProjectUser>();

  const userTable = useReactTable({
    data: projectUsers,
    columns: [
      userColumnHelper.display({
        id: "checkbox",
        header: () => <span></span>,
        cell: (props: any) => <Checkbox onChange={() => {}} />,
      }),
      userColumnHelper.accessor("users.id", {
        header: () => <span>#ID</span>,
        cell: (info) => info.getValue(),
      }),
      userColumnHelper.display({
        id: "fullname",
        header: () => <span>Name</span>,
        cell: (props: any) => (
          <p>
            {props.row.original.users.firstName}{" "}
            {props.row.original.users.lastName}
          </p>
        ),
      }),
      userColumnHelper.accessor("status", {
        header: () => <span>Status</span>,
        cell: (info: any) => {
          return (
            <ToggleSwitch
              color="success"
              checked={info.getValue() === "ACTIVE"}
              onChange={() =>
                info.table.options.meta.handleToggleUserStatus(
                  info.row.original
                )
              }
            />
          );
        },
      }),
      userColumnHelper.display({
        id: "actions",
        header: () => <div className="float-right">Actions</div>,
        cell: (props: any) => (
          <div className="flex items-center justify-end gap-3">
            <BiTrashAlt
              className="cursor-pointer"
              onClick={() =>
                props.table.options.meta.handleDeleteUser(props.row.original)
              }
            />
          </div>
        ),
      }),
    ],
    getCoreRowModel: getCoreRowModel(),
    meta: {
      handleToggleUserStatus,
      handleDeleteUser,
    },
  });

  const serviceColumnHelper = createColumnHelper<Service>();

  const serviceTable = useReactTable({
    data: projectServices ?? [],
    columns: [
      serviceColumnHelper.display({
        id: "checkbox",
        header: () => <span></span>,
        cell: (props: any) => <Checkbox onChange={() => {}} />,
      }),
      serviceColumnHelper.accessor("name", {
        header: () => <span>Service Name</span>,
        cell: (info) => info.getValue(),
      }),
      serviceColumnHelper.accessor("status", {
        header: () => <span>Status</span>,
        cell: (info: any) => {
          return (
            <ToggleSwitch
              color="success"
              checked={info.getValue() === "ACTIVE"}
              onChange={() =>
                info.table.options.meta.handleToggleServiceStatus(
                  info.row.original
                )
              }
            />
          );
        },
      }),
      serviceColumnHelper.display({
        id: "actions",
        header: () => <div className="float-right">Actions</div>,
        cell: (props: any) => (
          <div className="flex items-center justify-end gap-3">
            <BiTrashAlt
              className="cursor-pointer"
              onClick={() =>
                props.table.options.meta.handleDeleteService(props.row.original)
              }
            />
          </div>
        ),
      }),
    ],
    getCoreRowModel: getCoreRowModel(),
    meta: {
      handleToggleServiceStatus,
      handleDeleteService,
    },
  });

  const handleSelectHostingRecord = async (event: any) => {
    try {
      const username = event.target.value;
      if (username) {
        await assignToProject(project.id, username);
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  }

  const handleSelectAlphaHostingRecord = async (event: any) => {
    try {
      const uid = event.target.value;
      if (uid) {
        await assignToProjectAlpha(project.id, uid);
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  }

  return (
    <Modal show={isOpen} onClose={closeModal} size="2xl">
      <Modal.Header>
        Project #{project.id} - {project.name}
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="flex w-md flex-col gap-4 mb-5">
            <div className="flex justify-between items-center">
              <p className="font-bold">Overview</p>
              <div className="flex items-center">
                <div className="flex me-5">
                  <ToggleSwitch
                    color="success"
                    checked={project.status === "ACTIVE"}
                    onChange={() => handleToggleProjectStatus()}
                  />
                  <p className="ms-2">{project.status}</p>
                </div>
                <Button
                  color="gray"
                  size="xs"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <BiTrashAlt
                    className="mr-2 h-5 w-5 cursor-pointer"
                    onClick={() => {}}
                  />
                  Delete project
                </Button>
              </div>
            </div>

            <div className="grid grid-cols-3 items-center">
              <div className="col-span-3">
                <Label color="red" htmlFor="balance" value="Balance" />
              </div>
              <div className="col-span-3">
                <p>{project.balance} EUR</p>
              </div>
            </div>

            <div className="grid grid-cols-3 items-center">
              <div className="col-span-3">
                <Label htmlFor="geography" value="Geography" />
              </div>
              <div className="col-span-3">
                <p>{project.geography}</p>
              </div>
            </div>

            <div className="grid grid-cols-3 items-center">
              <div className="col-span-3">
                <Label htmlFor="status" value="Status" />
              </div>
              <div className="col-span-3">
                {project.status === "ACTIVE" && (
                  <Badge className="w-fit" color="success" size="sm">
                    ACTIVE
                  </Badge>
                )}
                {seletedProject.status === "SUSPENDED" && (
                  <Badge className="w-fit" color="gray" size="sm">
                    SUSPENDED
                  </Badge>
                )}
              </div>
            </div>
          </div>

          <hr />

          <div className="flex w-md flex-col gap-4 mt-5">
            <div className="flex justify-between items-center">
              <p className="font-bold">Users</p>
              <Button
                color="gray"
                size="xs"
                onClick={() => setOpenAddUser(!openAddUser)}
              >
                <FiPlus className="mr-2 h-5 w-5" />
                Add user
              </Button>
            </div>

            <div className="grid grid-cols-1 items-center">
              {openAddUser && (
                <div className="col-span-1">
                  <AsyncPaginate
                    placeholder="Search by user ID, email or name"
                    defaultOptions
                    debounceTimeout={500}
                    loadOptions={promiseUserOptions}
                    onChange={onAssignUser}
                    className="react-select-search"
                  />
                </div>
              )}

              <div className="col-span-1">
                <Table>
                  <Table.Head>
                    {userTable.getHeaderGroups().map((headerGroup) => {
                      return headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          className="bg-transparent"
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ));
                    })}
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {userTable.getRowModel().rows.map((row) => (
                      <Table.Row
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={row.id}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>

          <hr />

          <div className="flex w-md flex-col gap-4 mt-5">
            <p className="font-bold">Services</p>
            <div className="grid grid-cols-1 items-center">
              <div className="col-span-1">
                <Table>
                  <Table.Head>
                    {serviceTable.getHeaderGroups().map((headerGroup) => {
                      return headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          className="bg-transparent"
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ));
                    })}
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {serviceTable.getRowModel().rows.map((row) => (
                      <Table.Row
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={row.id}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>

          {enabledHostingService && (
            <>
              <hr />

              <div className="flex w-md flex-col gap-4 mt-5">
                <p className="font-bold">Select Hosting</p>
                <Select onChange={handleSelectHostingRecord} id="hostingRecordId" sizing="sm">
                  <option value={''}>
                    {''}
                  </option>
                  {hostingRecords?.map((hr: any) => (
                    <option value={hr.cpanelUsername}>
                      {hr.cpanelUsername}
                    </option>
                  ))}
                </Select>
              </div>
            </>
          )}

          {enabledAlphaHostingService && (
            <>
              <div className="flex w-md flex-col gap-4 mt-5">
                <p className="font-bold">Select Alpha Hosting</p>
                <Select onChange={handleSelectAlphaHostingRecord} id="hostingRecordId" sizing="sm">
                  <option value={''}>
                    {''}
                  </option>
                  {alphaHostingRecords?.map((hr: any) => (
                    <option value={hr.uid}>
                      {hr.username}
                    </option>
                  ))}
                </Select>
              </div>
            </>
          )}

          <DeleteProjectModal
            isOpen={openDeleteModal}
            closeModal={handleCloseRemoveModal}
            seletedProject={seletedProject}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
