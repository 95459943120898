import {
  Button,
  Label,
  Modal,
  Select,
  TextInput,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { countryPhoneDataOptions } from "../../utils/SelectOptions/telephone";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { User } from "../../types";
import { useCreateUser } from "../../hooks/user.hook";

interface AddUserModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export default function AddUserModal(props: AddUserModalProps) {
  const { isOpen, closeModal } = props;
  const { mutate: createUser } = useCreateUser();
  const [phoneNumbers, setPhoneNumbers] = useState([
    {
      countryCode: "+49",
      phoneNumber: "",
    },
  ]);

  const initState = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
    contacts: [
      {
        countryCode: "+49",
        phoneNumber: "",
      },
    ],
  };

  const [initialValues] = useState(initState);

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string().required(),
    passwordConfirmation: Yup.string().required(),
    contacts: Yup.array()
      .of(
        Yup.object().shape({
          countryCode: Yup.string().required(),
          phoneNumber: Yup.string().required(),
        })
      )
      .required(),
  }).required();

  const onSubmit = async (values: Partial<User>) => {
    createUser(values);
    closeModal();
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!isOpen) {
      reset(initialValues);
    }
  }, [initialValues, isOpen, reset]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "contacts") {
        if (value.contacts) {
          setPhoneNumbers(
            value.contacts as { countryCode: string; phoneNumber: string }[]
          );
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const handleAddNewNumber = () => {
    const currentPhoneNumbers = getValues("contacts");
    setValue("contacts", [
      ...currentPhoneNumbers,
      { countryCode: "+49", phoneNumber: "" },
    ]);
  };
  return (
    <Modal show={isOpen} onClose={closeModal} size="2xl">
      <Modal.Header>Add User</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex w-md flex-col gap-4">
              <p className="font-bold">Name</p>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="firstName" value="First Name" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="firstName"
                    sizing="sm"
                    type="text"
                    color={Boolean(errors.firstName) ? "failure" : ""}
                    helperText={
                      Boolean(errors.firstName) && errors.firstName?.message
                    }
                    {...register("firstName")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="lastName" value="Last Name" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="lastName"
                    sizing="sm"
                    type="text"
                    color={Boolean(errors.lastName) ? "failure" : ""}
                    helperText={
                      Boolean(errors.lastName) && errors.lastName?.message
                    }
                    {...register("lastName")}
                  />
                </div>
              </div>

              <p className="font-bold mt-5">Password</p>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="password" value="Password" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="password"
                    sizing="sm"
                    type="password"
                    color={Boolean(errors.password) ? "failure" : ""}
                    helperText={
                      Boolean(errors.password) && errors.password?.message
                    }
                    {...register("password")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label
                    htmlFor="passwordConfirmation"
                    value="Password Confirmation"
                  />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="passwordConfirmation"
                    sizing="sm"
                    type="password"
                    color={
                      Boolean(errors.passwordConfirmation) ? "failure" : ""
                    }
                    helperText={
                      Boolean(errors.passwordConfirmation) &&
                      errors.passwordConfirmation?.message
                    }
                    {...register("passwordConfirmation")}
                  />
                </div>
              </div>

              <p className="font-bold mt-5">Contact</p>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="email" value="Email" />
                </div>
                <div className="col-span-2">
                  <TextInput
                    id="email"
                    sizing="sm"
                    type="email"
                    color={Boolean(errors.email) ? "failure" : ""}
                    helperText={Boolean(errors.email) && errors.email?.message}
                    {...register("email")}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1">
                  <Label htmlFor="phoneNumber" value="Phone Number" />
                </div>
                <div className="col-span-2">
                  {phoneNumbers.map((_phoneNumber, index) => (
                    <div
                      key={`phoneNumber-${index}`}
                      className="grid grid-cols-6 gap-3"
                    >
                      <div className="col-span-2">
                        <Select
                          id="countryCode"
                          sizing="sm"
                          {...register(`contacts.${index}.countryCode`)}
                        >
                          {countryPhoneDataOptions.map(
                            (option: { value: string; name: any }) => (
                              <option value={option.value}>
                                {option.name}
                              </option>
                            )
                          )}
                        </Select>
                      </div>
                      <div className="col-span-4">
                        <TextInput
                          id="phoneNumber"
                          sizing="sm"
                          type="text"
                          color={
                            Boolean(errors.contacts?.[index]?.phoneNumber)
                              ? "failure"
                              : ""
                          }
                          helperText={
                            Boolean(errors.contacts?.[index]?.phoneNumber) &&
                            errors.contacts?.[index]?.phoneNumber?.message
                          }
                          {...register(`contacts.${index}.phoneNumber`)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-span-12">
                  <div className="flex justify-end">
                    <Button
                      className="text-blue-500 border-none"
                      onClick={handleAddNewNumber}
                    >
                      + Add new number
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center gap-2 mt-5">
              <Button pill color="gray" onClick={closeModal}>
                Cancel
              </Button>
              <Button pill color="blue" type="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
