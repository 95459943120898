import { Button, Modal } from "flowbite-react";
import { HostingAccount } from "../../types/hostingAccount.type";
import { useDeleteHostingAccount, useHostingAccounts } from "../../hooks/cpanel.hook";
import { toast } from "react-toastify";

interface DeleteHostingAccountModalProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedAccount: HostingAccount;
  currentPage: number;
}

export default function DeleteHostingAccountModal(
  props: DeleteHostingAccountModalProps
) {
  const { isOpen, closeModal, selectedAccount, currentPage } = props;
  const { mutateAsync: deleteHostingAccount } = useDeleteHostingAccount();
  const { refetch } = useHostingAccounts({ page: currentPage });

  const onClickDelete = async () => {
    toast.info('Deleting account!');
    await deleteHostingAccount(selectedAccount.user);
    await refetch();
    closeModal();
  };

  return (
    <>
      <Modal show={isOpen} size="md" popup onClose={closeModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h1 className="text-lg font-bold mb-5">Delete project?</h1>
            <h3 className="text-left mb-8 px-10 text-sm font-normal text-gray-500 dark:text-gray-400">
              All actions in the activity feed will be erased
            </h3>
            <div className="flex justify-center gap-40">
              <Button
                className="w-full"
                outline
                color="failure"
                onClick={onClickDelete}
              >
                <p className="text-red-500">Delete</p>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
