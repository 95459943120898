import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios";
import { Calendar } from "../types";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchCalendars = async (queryParams: {
  serviceId: number;
  page: number;
  status?: string;
}): Promise<Calendar[]> => {
  const { serviceId } = queryParams;

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/services/${serviceId}/units`
  );
  const { units } = response.data;
  return units ?? [];
};

const updateCalendar = async (
  calendarId: number,
  payload: Partial<Calendar>
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/services/units/${calendarId}`,
    payload
  );

  const { unit } = response.data;
  return unit ?? null;
};

const deleteCalendar = async (
  calendarId: number
) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/services/units/${calendarId}`,
  );

  const { unit } = response.data;
  return { id: calendarId, unit } ?? null;
};

const useCalendars = (queryParams: {
  serviceId: number;
  page: number;
  status?: string;
}) => {
  const { serviceId, page, status } = queryParams;

  return useQuery({
    queryKey: ["calendars", page],
    queryFn: () => fetchCalendars({ serviceId, page, status }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true,
  });
};

const useUpdateCalendar = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Partial<Calendar>) =>
      updateCalendar(payload.id!, payload),
    onSuccess: (data: Calendar) => {
      queryClient.setQueryData(
        ["calendars", 1],
        (currentData: Calendar[] | undefined) => {
          if (currentData && currentData.length) {
            const newCalendars = currentData.filter(
              (item) => item.id !== data.id
            );
            return [data, ...newCalendars];
          }
          return currentData;
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useDeleteCalendar = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (calendarId: number) => deleteCalendar(calendarId),
    onSuccess: (data: { id: number }) => {
      queryClient.setQueryData(
        ["calendars", 1],
        (currentData: Calendar[] | undefined) => {
          if (currentData && currentData.length) {
            return currentData.filter((item) => item.id !== data.id);
          }
          return [];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

export { useCalendars, useUpdateCalendar, useDeleteCalendar };
