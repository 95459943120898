import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Label, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { ChangePasswordInputs } from "../../interfaces/ChangePassword.inferface";

interface ChangePasswordFormProps {
  setChangePasswordMode: (value: boolean) => void;
  handeChangePassword: (values: ChangePasswordInputs) => void;
}

export default function ChangePasswordForm(props: ChangePasswordFormProps) {
  const { handeChangePassword, setChangePasswordMode } = props;

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    newPasswordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
  });

  const onSubmit = async (values: ChangePasswordInputs) => {
    handeChangePassword(values)
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="mt-0 flex w-md flex-col gap-4">
          <p className="font-bold">Change Password</p>
          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">
              <Label htmlFor="currentPassword" value="Current Password" />
            </div>
            <div className="col-span-2">
              <TextInput
                id="currentPassword"
                sizing="sm"
                type="password"
                color={Boolean(errors.currentPassword) ? "failure" : ""}
                helperText={
                  Boolean(errors.currentPassword) &&
                  errors.currentPassword?.message
                }
                {...register("currentPassword")}
              />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">
              <Label htmlFor="newPassword" value="New Password" />
            </div>
            <div className="col-span-2">
              <TextInput
                id="newPassword"
                sizing="sm"
                type="password"
                color={Boolean(errors.newPassword) ? "failure" : ""}
                helperText={
                  Boolean(errors.newPassword) && errors.newPassword?.message
                }
                {...register("newPassword")}
              />
            </div>
          </div>

          <div className="grid grid-cols-3 items-center">
            <div className="col-span-1">
              <Label
                htmlFor="newPasswordConfirmation"
                value="Password Confirmation"
              />
            </div>
            <div className="col-span-2">
              <TextInput
                id="newPasswordConfirmation"
                sizing="sm"
                type="password"
                color={Boolean(errors.newPasswordConfirmation) ? "failure" : ""}
                helperText={
                  Boolean(errors.newPasswordConfirmation) &&
                  errors.newPasswordConfirmation?.message
                }
                {...register("newPasswordConfirmation")}
              />
            </div>
          </div>

          <div className="flex justify-end items-center gap-2 mt-5">
            <Button
              pill
              color="gray"
              onClick={() => setChangePasswordMode(false)}
            >
              Back
            </Button>

            <Button pill color="blue" type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
