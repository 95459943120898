import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { User } from "../types";
import axios from "../axios";
import { ChangePasswordInputs } from "../interfaces/ChangePassword.inferface";

const STALE_TIME = 5 * 60 * 1000; // 5 mins

const fetchUsers = async (queryParams: { page: number, status?: string }): Promise<User[]> => {
  let params = `page=${queryParams.page}`;

  if (queryParams.status && queryParams.status !== "ALL") {
    params = params + `&status=${queryParams.status}`;
  }

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/users?${params}`
  );
  const { users } = response.data;
  return users ?? [];
}

const searchUsers = async (queryKey: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/users/search?searchKey=${queryKey}`,
  );
  const { users } = response.data;
  return users ?? null;
};

const createUser = async (payload: Partial<User>) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/users`,
    payload
  );
  const { user } = response.data;
  return user ?? null;
};

const updateUser = async (userId: number, payload: Partial<User>) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/users/${userId}`,
    payload
  );
  const { user } = response.data;
  return user ?? null;
};

const deleteUser = async (userId: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/admin/users/${userId}`
  );
  const { id, deleted } = response.data;

  return { id, deleted } ?? false;
};

const changePasswordUser = async (userId: number, payload: ChangePasswordInputs) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/admin/users/${userId}/changePassword`,
    payload
  );
  const { user } = response.data;
  return user ?? null;
}

const useUsers = (queryParams: { page: number, status?: string }) => {
  const { page, status } = queryParams;

  return useQuery({
    queryKey: ['users', page],
    queryFn: () => fetchUsers({ page, status }),
    staleTime: STALE_TIME,
    enabled: false,
    keepPreviousData: true
  });
}

const useCreateUser = (queryParams: { page: number } = { page: 1 }) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (payload: Partial<User>) => createUser(payload),
    onSuccess: (data: User) => {
      queryClient.setQueryData(
        ["users", page],
        (currentData: User[] | undefined) => {
          if (currentData && currentData.length) {
            return [data, ...currentData];
          }
          return [data];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useUpdateUser = (queryParams: { page: number } = { page: 1 }) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (payload: Partial<User>) => updateUser(payload.id!, payload),
    onSuccess: (data: User) => {
      queryClient.setQueryData(
        ["users", page],
        (currentData: User[] | undefined) => {
          if (currentData && currentData.length) {
            // const newUsers = currentData.filter((item) => item.id !== data.id);
            const uIndex = currentData.findIndex((u) => u.id === data.id);
            const current = currentData.find((u) => u.id === data.id);
            const mData = [...currentData];
            mData[uIndex] = { ...current, ...data };
            return mData;
          }
          return currentData;
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useDeleteUser = (queryParams: { page: number } = { page: 1 }) => {
  const queryClient = useQueryClient();
  const { page } = queryParams;

  return useMutation({
    mutationFn: (userId: number) => deleteUser(userId),
    onSuccess: (data: { id: number }) => {
      queryClient.setQueryData(
        ["users", page],
        (currentData: User[] | undefined) => {
          if (currentData && currentData.length) {
            return currentData.filter((item) => item.id !== data.id);
          }
          return [];
        },
        { updatedAt: Date.now() }
      );
    },
  });
};

const useChangePasswordUser = () => {
  return useMutation({
    mutationFn: (payload: ChangePasswordInputs) => changePasswordUser(payload.id!, payload),
  });
};

export { useUsers, useCreateUser, useDeleteUser, useUpdateUser, useChangePasswordUser, searchUsers };
