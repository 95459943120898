import { authProvider } from "../hooks/auth.hook";
import LogoMain from "../assets/images/logos/logo-main.png";
import AccountSettingIcon from "../assets/images/icons/setting.png";
import NotificationIcon from "../assets/images/icons/notifications.png";
import { Button, Tooltip } from "flowbite-react";
import { BiLogOut } from "react-icons/bi";

export default function Header() {
  const logout = async () => {
    await authProvider.signout();
    return (window.location.href = "");
  };

  return (
    <nav className="max-w-full bg-white border-gray-200 bg-[#CEDDFA]">
      <div className="max-w-full flex flex-wrap items-center justify-between mx-auto py-2 px-5">
        <a href="#home" className="flex items-center">
          <img
            alt=""
            src={LogoMain}
            width="72"
            height="26"
            className="d-inline-block align-top"
          />{" "}
        </a>
        <div className="flex items-center justify-center px-2 py-1 rounded-md bg-[#E2EBFB]">
          {authProvider.isAuthenticated && (
            <Tooltip content="Logout">
              <Button
                aria-label="Logout"
                onClick={logout}
                pill
                size="xs"
                style={{ color: "#8A8B8F", height: 20 }}
              >
                <BiLogOut className="h-6 w-6" />
              </Button>
            </Tooltip>
          )}

          <img src={NotificationIcon} className="mr-3" width={20} height={20} />
          <img
            src={AccountSettingIcon}
            className="mr-1"
            width={20}
            height={20}
          />
          <text className="text-[16px] font-semibold text-[#8A8B8F]">
            {authProvider.login}
          </text>
        </div>
      </div>
    </nav>
  );
}
